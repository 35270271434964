import React, {useEffect, useState} from "react";
import {inject, observer} from 'mobx-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faCog, faPencilAlt, faSpinner, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import Modal from '../../components/modal';
import Switch from "react-switch";
import Select from 'react-select';
import AdminNavigationBar from "../../components/admin-navigation-bar";
import FilterInputField from "../../components/filter-input-field";
import PaginationControls from "../../components/pagination-controls";

import { fetch } from '../../utils/api-wrapper';


const AdminUsers = ({data}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null);
    const [clients, setClients] = useState(null);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterValue, setFilterValue] = useState("");
    const [sorting, setSorting] = useState([{"id": "email", "desc": false}]);
    const [newUser, setNewUser] = useState({email: "", first_name: "", last_name: "", role: "Admin", supplier_number: ""});
    const [updatedUser, setUpdatedUser] = useState({
        _id: null,
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        supplier_number: ""
    });
    const [originalUser, setOriginalUser] = useState({
        _id: null,
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        supplier_number: ""
    });
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [showResetPasswordConfirm, setShowResetPasswordConfirm] = useState(false);
    const [userToResetPassword, setUserToResetPassword] = useState(null);
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
    const [userDeleteSelection, setUserDeleteSelection] = useState(null);
    

    useEffect(() => {
        setLoading(true);
        refreshClients();
    }, []);

    useEffect(() => {
        if (client) {
            setPage(1);
            refreshUsers(client);
        }
    }, [client, pageSize, sorting, filterValue]);

    useEffect(() => {
        if (client) {
            refreshUsers(client)
        }
    }, [page]);

    const refreshClients = () => {
        fetch("/clients/", "GET", {}, response => {
                const clients = response.data.clients;
                setClients(clients);

                setClient(clients.find(c => c._id === data.id_client)); //Set Client to the user's corresponding client
                setLoading(false);
            },
            error => {
                // Error handling
                setErrorMessage(data.t.read("failed_to_fetch_clients"));
                setLoading(false);
            }
        );
    }

    const selectClient = (client) => {
        setClient({...client})
        setPage(1)
        setFilterValue("");
        refreshUsers(client);
    }

    const refreshUsers = (selectedClient = client) => {
        let start = (page - 1) * pageSize;
        const filters = [{id: "id_client", value: selectedClient._id}];

        if (filterValue && filterValue.trim() !== "") {
            filters.push({id: "search", value: filterValue.trim()});
        }
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(start)}&page_size=${pageSize}`;
        fetch(  `/users/${searchData}`, "GET", {}, response => {
            let fetchedUsers = response.data.users;
            setUsers([...fetchedUsers]);
            setTotalRowCount(response.data.meta?.totalRowCount);
            setLoading(false);
        });
    }

    // Form submission handler
    const updateUserSubmit = (e) => {
        e.preventDefault();
        setShowUpdateUserModal(false)
        updateUser()
    };


    const updateUser = (userId = updatedUser._id?.$oid, user = updatedUser) => {
        const updatedUserWithoutId = {...user}; // Shallow copy of updatedUser
        delete updatedUserWithoutId._id; // Remove the _id field
        const formData = new FormData();
        formData.append('user', JSON.stringify(updatedUserWithoutId));
        fetch(  `/users/${userId}`, "PUT", formData,
            () => {
                refreshUsers(client);
                setSuccessMessage(data.t.read("user_updated_successfully"))
            },
            () => {
                setErrorMessage(data.t.read("failed_to_update_user"))
                setLoading(false);
            }
        );
    }

    //function to check if a user has been updated
    const isUserUpdated = (updatedUser, originalUser) => {
        return (
            updatedUser.email !== originalUser.email ||
            updatedUser.first_name !== originalUser.first_name ||
            updatedUser.last_name !== originalUser.last_name ||
            updatedUser.active !== originalUser.active ||
            updatedUser.role !== originalUser.role ||
            updatedUser.supplier_number !== originalUser.supplier_number

        );
    };

    const openUpdateUserModal = (selectedUser) => {
        setUpdatedUser({
            ...selectedUser,
            _id: selectedUser._id || null,
            email: selectedUser.email || "",
            first_name: selectedUser.first_name || "",
            last_name: selectedUser.last_name || "",
            role: selectedUser.role || "",
            supplier_number: selectedUser.supplier_number || ""
        });
        setOriginalUser({
            ...selectedUser,
            _id: selectedUser._id || null,
            email: selectedUser.email || "",
            first_name: selectedUser.first_name || "",
            last_name: selectedUser.last_name || "",
            role: selectedUser.role || "",
            supplier_number: selectedUser.supplier_number || ""
        })
        setShowUpdateUserModal(true)
    }

    const cancelUpdateUserModal = () => {
        setShowUpdateUserModal(false);
        setUpdatedUser({
            _id: null,
            email: "",
            first_name: "",
            last_name: "",
            role: "",
            supplier_number: ""
        })
        setOriginalUser({
            _id: null,
            email: "",
            first_name: "",
            last_name: "",
            role: "",
            supplier_number: ""
        })
    }

    const deleteUsers = () => {

        fetch("/users/", "DELETE", {"user_ids": [userDeleteSelection._id?.$oid]},
            () => {
                refreshUsers();
                setSuccessMessage(data.t.read("user_deleted_successfully"))
                setUserDeleteSelection(null)
            },
            (error) => {
                setErrorMessage(data.t.read("failed_to_delete"))
                setLoading(false);
            }
        );
    }

    const confirmDeleteUser = () => {
        deleteUsers()
        setShowDeleteUserModal(false);
    };

    const openDeleteUserModal = () => {
        setShowDeleteUserModal(true);
    }

    const cancelDeleteOfficeModal = () => {
        setShowDeleteUserModal(false);
        setUserDeleteSelection(null)
    }

    const userCanModify = (user) => {
        return client.config.roles.indexOf(user.role) >= client.config.roles.indexOf(data.role);
    }


    const generatePassword = (user) => {
        setUserToResetPassword(user);
        setShowResetPasswordConfirm(true);
    };

    const confirmResetPassword = () => {
        setUserToResetPassword({...userToResetPassword, is_generating: true})

        const formData = new FormData();
        formData.append("user", JSON.stringify(userToResetPassword));
        fetch("/users/generate_password",
            "PUT",
            formData,
            (response) => {
                setSuccessMessage(
                    `${userToResetPassword.first_name} ${userToResetPassword.last_name}\n` + data.t.read("password_reset_successful")
                );
                setShowResetPasswordConfirm(false);
                setUserToResetPassword(null);
            },
            (response) => {
                setErrorMessage(
                    `${userToResetPassword.first_name} ${userToResetPassword.last_name}\n` + data.t.read("password_reset_failed")
                );
                setShowResetPasswordConfirm(false);
                setUserToResetPassword(null);
            }
        );
    };

    const cancelResetPassword = () => {
        setShowResetPasswordConfirm(false);
        setUserToResetPassword(null);
    };


    const createUser = (user = newUser) => {
        // Create form data to send the user data in 'request.form'
        const formData = new FormData();
        formData.append('user', JSON.stringify(user)); // The API expects 'user' in form data

        fetch("/users/", "POST", formData,
            () => {
                setSuccessMessage(data.t.read("user_created_successfully"));
                refreshUsers(client);
            },
            () => {
                alert("Error");
                setLoading(false);
            }
        );
    };

    const newUserSubmit = (e) => {
        e.preventDefault();
        setShowNewUserModal(false)
        createUser();
    };

    const openNewUserModal = () => {
        setShowNewUserModal(true);
        setNewUser({
            id_client: client._id,
            email: "",
            first_name: "",
            last_name: "",
            role: newUserAvailableRoles()[0] ?? "Admin",
        });
    };


    const cancelNewUserModal = () => {
        setShowNewUserModal(false);
        // Reset newUser fields
        setNewUser({
            email: "",
            first_name: "",
            last_name: "",
            role: newUserAvailableRoles()[0] ?? "Admin",
        });
    };

    const newUserAvailableRoles = () => {
        if (client === null) return []
        let roles = client.config.roles
        let returnRoles = [];

        // if SuperAdmin, only Admin is available
        if (data.role === "SuperAdmin") {
            returnRoles.push("Admin")
        }
        // if Admin, all roles equal or below Admin are available
        else if (data.role === "Admin") {
            for (let i = roles.indexOf("Admin"); i < roles.length; i++) {
                returnRoles.push(roles[i]);
            }
        }
        return returnRoles;
    }

    const sortTable = (name) => {
        const sorter = sorting.find(s => s.id === name)
        if (sorter === undefined)
            return setSorting([{"id": name, "desc": true}])

        if (sorter.desc)
            return setSorting([{"id": name, "desc": false}])
        else
            return setSorting([])
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (rows) => {
        setPage(1)
        setPageSize(rows)
    }

    //pagination logic
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + users.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - users.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    return (
        <div className="card pl-4 pr-4 pb-3 rounded-lg">
            <AdminNavigationBar data={data} adminPage={"users"}></AdminNavigationBar>
            <div>
                {!loading ?
                    <div>
                        <div>
                            <div className='pagination-row'>
                                <FilterInputField filterValue={filterValue} setFilterValue={setFilterValue}
                                                  placeholder={""}></FilterInputField>
                                <PaginationControls
                                    page={page}
                                    totalPages={totalPages}
                                    indexOfFirstItem={indexOfFirstItem}
                                    indexOfLastItem={indexOfLastItem}
                                    handlePageChange={handlePageChange}
                                    totalItems={totalRowCount}
                                    data={data}
                                    handleRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPage={pageSize}
                                ></PaginationControls>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-4 py-2">
                                {data.role === "SuperAdmin" ? (
                                        <div className="flex mr-3">
                                            <Select
                                                name="client"
                                                options={clients.map(client => ({value: client, label: client.name}))}
                                                styles={styleSelect}
                                                value={client === null ? null : {value: client, label: client.name}}
                                                classNamePrefix="select"
                                                onChange={(choice) => {
                                                    selectClient(choice.value);
                                                }}
                                            />
                                        </div>
                                    )
                                    :
                                    client ? (// Display the client name for Admins
                                        <div>{client.name}</div>
                                    ) : null
                                }
                                <div className="d-flex">
                                    <button
                                        className='btn btn-primary btn-sm ml-2'
                                        type="button" disabled={client === null}
                                        onClick={openNewUserModal}
                                    >
                                        {data.t.read("add")}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-2 card rounded-lg admin-table">
                                <table className='table table-hover font-small'>
                                    <thead>
                                    <tr>
                                            <th className=" pointer col-3" onClick={() => sortTable("email")}>
                                                {data.t.read("rule.email")}
                                                {sorting.find(s => s.id === "email") &&
                                                    <FontAwesomeIcon
                                                        className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                        icon={sorting.find(s => s.id === "email").desc ? faArrowDown : faArrowUp}/>}
                                            </th>
                                            <th className="pointer col-2" onClick={() => sortTable("first_name")}>
                                                {data.t.read("first_name")}
                                                {sorting.find(s => s.id === "first_name") &&
                                                    <FontAwesomeIcon
                                                        className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                        icon={sorting.find(s => s.id === "first_name").desc ? faArrowDown : faArrowUp}/>}
                                            </th>
                                            <th className="pointer col-2" onClick={() => sortTable("last_name")}>
                                                {data.t.read("last_name")}
                                                {sorting.find(s => s.id === "last_name") &&
                                                    <FontAwesomeIcon
                                                        className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                        icon={sorting.find(s => s.id === "last_name").desc ? faArrowDown : faArrowUp}/>}
                                            </th>
                                            <th className="pointer col-1" onClick={() => sortTable("role")}>
                                                {data.t.read("client_table.role")}
                                                {sorting.find(s => s.id === "role") &&
                                                    <FontAwesomeIcon
                                                        className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                        icon={sorting.find(s => s.id === "role").desc ? faArrowDown : faArrowUp}/>}
                                            </th>
                                            <th className="col-1 text-center">
                                                {data.t.read("client.users.active")}

                                            </th>
                                            <th className="col-2 text-center">
                                                {data.t.read("password")}
                                            </th>
                                            <th className="col-1 text-center">
                                                Actions
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            users.map((user) => {
                                                return <tr hidden={user?.is_deleted} key={user._id?.$oid}>
                                                    <td className="col-3" onClick={() => openUpdateUserModal(user)}>
                                                        {user.email}
                                                    </td>

                                                    <td className="col-2" onClick={() => openUpdateUserModal(user)}>
                                                        {user.first_name}
                                                    </td>
                                                    <td className="col-2" onClick={() => openUpdateUserModal(user)}>
                                                        {user.last_name}

                                                    </td>
                                                    <td className="col-1" onClick={() => openUpdateUserModal(user)}>
                                                        {user.role}
                                                    </td>
                                                    <td className="col-1">
                                                        <div className=" d-flex justify-content-center align-items-center">
                                                            <Switch
                                                                className='switch mt-0  '
                                                                checked={user.active}
                                                                disabled={!userCanModify(user)}
                                                                width={70}
                                                                onColor="#F39800"
                                                                onHandleColor="#2693e6"
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                onChange={(active) => updateUser(user._id?.$oid, {
                                                                    ...user,
                                                                    active
                                                                })}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="col-2 ">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <button className="btn btn-sm btn-primary "
                                                                    disabled={!userCanModify(user) | ((user._id === userToResetPassword?._id) && userToResetPassword?.is_generating) | !user._id}
                                                                    onClick={() => generatePassword(user)}>
                                                                <FontAwesomeIcon
                                                                    className={((user._id === userToResetPassword?._id) && userToResetPassword?.is_generating) ? "mr-2 infinite-rotate" : "mr-2"}
                                                                    icon={faCog}/>
                                                                <span>Reset</span>
                                                            </button>
                                                        </div>
                                                    </td>

                                                    <td className=" col-1 ">
                                                    <div className=" d-flex justify-content-center align-items-center">
                                                            {<FontAwesomeIcon className="mx-2 my-auto"
                                                                              icon={faPencilAlt}
                                                                              onClick={() => openUpdateUserModal(user)}
                                                                              type="button"/>}

                                                            {<FontAwesomeIcon icon={faWindowClose} type="button"
                                                                              onClick={() => {
                                                                                  setUserDeleteSelection(user);
                                                                                  openDeleteUserModal()
                                                                              }}
                                                                              style={{color: 'red'}}/>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            emptyRows.map((_, idx) => (
                                                <tr key={`empty-${idx}`}>
                                                    <td colSpan="7">&nbsp;</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                            </div>
                        </div>
                        {showDeleteUserModal && (
                            <Modal
                                title={` ${userDeleteSelection?.first_name} ${userDeleteSelection?.last_name}`}
                                okAction={confirmDeleteUser}
                                cancelAction={cancelDeleteOfficeModal}
                                okText={data.t.read("delete")}
                                cancelText={data.t.read("cancel")}>
                                <p className='text-danger'>{data.t.read("confirm_delete_user")}</p>
                            </Modal>
                        )}
                        {
                            errorMessage && (
                                <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>
                                    <p style={{whiteSpace: 'pre-line'}} className='text-danger'>{errorMessage}</p>
                                </Modal>)
                        }
                        {
                            successMessage && (
                                <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                                    {successMessage}
                                </Modal>

                            )
                        }
                        {showResetPasswordConfirm && (
                            <Modal
                                title="Confirm Password Reset"
                                okAction={confirmResetPassword}
                                cancelAction={cancelResetPassword}
                                okText="Reset Password"
                                cancelText="Cancel"
                            >
                                <p>
                                    {data.t.read("confirm_reset_password_for")}{" "}
                                    <strong>
                                        {userToResetPassword?.first_name} {userToResetPassword?.last_name}
                                    </strong>
                                    ?
                                </p>
                            </Modal>
                        )}
                        {showNewUserModal && (
                            <Modal
                                title={data.t.read("new_user")}>
                                {/* Form fields for new user */}
                                <form onSubmit={newUserSubmit}>
                                    <div className="form-group">
                                        <label>Email:</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            value={newUser.email}
                                            onChange={(event) => {
                                                setNewUser({...newUser, email: event.target.value})
                                            }}
                                            required
                                            placeholder={data.t.read("email")}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>{data.t.read("first_name")}:</label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="form-control"
                                            value={newUser.first_name}
                                            onChange={(event) => {
                                                setNewUser({...newUser, first_name: event.target.value})
                                            }}
                                            required
                                            placeholder={data.t.read("first_name")}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>{data.t.read("last_name")}:</label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            className="form-control"
                                            value={newUser.last_name}
                                            onChange={(event) => {
                                                setNewUser({...newUser, last_name: event.target.value})
                                            }}
                                            required
                                            placeholder={data.t.read("last_name")}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Role:</label>
                                        <select
                                            name="role"
                                            className="form-control"
                                            value={newUser.role}
                                            onChange={(event) => {
                                                setNewUser({...newUser, role: event.target.value})
                                            }}
                                            required
                                        >
                                            {newUserAvailableRoles().map(role => (
                                                <option value={role}>{role}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>{data.t.read("invoice.supplier_number")}:</label>
                                        <input
                                            type="text"
                                            name="supplier_number"
                                            className="form-control"
                                            value={newUser.supplier_number}
                                            onChange={(event) => {
                                                setNewUser({...newUser, supplier_number: event.target.value})
                                            }}
                                            
                                            placeholder={data.t.read("invoice.supplier_number")}
                                        />
                                    </div>

                                    <span className="d-flex justify-content-between">
                                        <button type="button" className={"btn btn-outline-dark"}
                                                onClick={cancelNewUserModal}>
                                            {data.t.read("cancel")}
                                        </button>
                                        <button type="submit" className="btn btn-primary">
                                            {data.t.read("save")}
                                        </button>
                                    </span>
                                </form>
                            </Modal>
                        )}
                        {showUpdateUserModal && (
                            <Modal title={originalUser.first_name + " " + originalUser.last_name}>
                                <form onSubmit={updateUserSubmit}>
                                    <div className="form-group">
                                        <label>Email:</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            value={updatedUser.email}
                                            onChange={(event) => {
                                                setUpdatedUser({...updatedUser, email: event.target.value})
                                            }}
                                            required
                                            placeholder={data.t.read("email")}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>{data.t.read("first_name")}:</label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="form-control"
                                            value={updatedUser.first_name}
                                            onChange={(event) => setUpdatedUser({
                                                ...updatedUser,
                                                first_name: event.target.value
                                            })}
                                            required
                                            placeholder={data.t.read("first_name")}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>{data.t.read("last_name")}:</label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            className="form-control"
                                            value={updatedUser.last_name}
                                            onChange={(event) => {
                                                setUpdatedUser({...updatedUser, last_name: event.target.value})
                                            }}
                                            required
                                            placeholder={data.t.read("last_name")}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Role:</label>
                                        <select
                                            name="role"
                                            className="form-control"
                                            value={updatedUser.role}
                                            onChange={(event) => {
                                                setUpdatedUser({...updatedUser, role: event.target.value})
                                            }}
                                            required
                                        >
                                            {newUserAvailableRoles().map(role => (
                                                <option key={role} value={role}>{role}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>{data.t.read("invoice.supplier_number")}:</label>
                                        <input
                                            type="text"
                                            name="supplier_number"
                                            className="form-control"
                                            value={updatedUser.supplier_number}
                                            onChange={(event) => {
                                                setUpdatedUser({...updatedUser, supplier_number: event.target.value})
                                            }}
                                            
                                            placeholder={data.t.read("invoice.supplier_number")}
                                        />
                                    </div>

                                    <div className="form-group  align-items-center">
                                        <label>{data.t.read("password")}:</label>
                                        <button className="btn btn-sm btn-primary d-block ml-2 "
                                                type="button"
                                                onClick={() => {
                                                    cancelUpdateUserModal()
                                                    generatePassword(originalUser)

                                                }}>
                                            <FontAwesomeIcon
                                                className={((originalUser._id === userToResetPassword?._id) && userToResetPassword?.is_generating) ? "mr-2 infinite-rotate" : "mr-2"}
                                                icon={faCog}/>
                                            <span>Reset</span>
                                        </button>
                                    </div>
                                    
                                    <div className="form-group align-items-center">
                                        <label>{data.t.read("client.users.active")}:</label>
                                        <Switch
                                            className='switch d-block mt-0 ml-2'
                                            checked={updatedUser.active}
                                            disabled={!userCanModify(updatedUser)}
                                            width={70}
                                            onColor="#F39800"
                                            onHandleColor="#2693e6"
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            onChange={(active) => setUpdatedUser({...updatedUser, "active": active})
                                            }
                                        />
                                    </div>
                                    <span className="d-flex justify-content-between">
                                        <button type="button" className={"btn btn-outline-dark"}
                                                onClick={cancelUpdateUserModal}>
                                            {data.t.read("cancel")}
                                        </button>
                                        <button type="submit" className="btn btn-primary"
                                                disabled={!isUserUpdated(updatedUser, originalUser)}>
                                            {data.t.read("save")}
                                        </button>
                                    </span>
                                </form>
                            </Modal>
                        )}
                    </div>
                    :
                    <div className="d-flex loading-container w-100">
                        <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
                    </div>
                }
            </div>
        </div>)
}
export default inject('data')(observer(AdminUsers));