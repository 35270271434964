import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/modal";
import ExpenseNavigationBar from "../components/expense_configuration-navigation-bar";
import {fetch} from "../utils/api-wrapper";
import MoneyInput from "../components/money-input";
import Select from 'react-select';

const ExpensesRates = ({data}) => {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [rates, setRates] = useState({});
    const [updatedRate, setUpdatedRate] = useState({});


    useEffect(() => {
        console.log(data);
        refreshRates();
    }, []);

    const refreshRates = () => {
        setLoading(true);
        fetch("/expense_rates/", "GET", {}, (response) => {
            // Expecting response.data to have something like {success: true, rates: {...}}
            const fetchedRates = response.data?.rates;
            setRates(fetchedRates);
            setUpdatedRate(fetchedRates);
            setLoading(false);
        }, () => {
            setErrorMessage(data.t.read("failed_to_fetch_rates"));
            setLoading(false);
        });
    };

    const handleRateSubmit = (e) => {
        e.preventDefault();
        updateRate();
    };

    const updateRate = () => {
        const client_id = data.id_client
        let formData = new FormData();
        formData.append("rates", JSON.stringify(updatedRate));

        fetch(`/expense_rates/${client_id}`, "PUT", formData, (response) => {
            // Assuming success response {status: "success"}
            if (response.data?.status === "success") {
                setSuccessMessage(data.t.read("update_successful"));
                refreshRates();
            } else {
                setErrorMessage(data.t.read("failed_to_update"));
            }
        }, () => {
            setErrorMessage(data.t.read("failed_to_update"));
            setLoading(false);
        });
    };

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles, border: "0 !important", boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    return (<div className="card pl-4 pr-4 pb-3 rounded-lg">
        <ExpenseNavigationBar data={data} page={"rates"}/>
        <div>
            {!loading ? <div>
                <div>
                    <div className=" ">
                        <form method="post" onSubmit={handleRateSubmit}>
                            <div className="form-group pb-3 border-bottom border-secondary">
                                <h4>{data.t.read("mileage")} :</h4>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{data.t.read("rate")} :
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <MoneyInput
                                            value={updatedRate.mileage_details?.rate || 0.0}
                                            onChange={(value) => setUpdatedRate({
                                                ...updatedRate, mileage_details: {
                                                    ...updatedRate.mileage_details, rate: value
                                                }
                                            })}
                                            locale={`${data.t.lang}-${data.t.lang.toUpperCase()}`}
                                            placeholder={data.t.read("rates")}
                                        />
                                    </div>
                                </div>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{data.t.read("currency")} :
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <Select
                                            styles={styleSelect}
                                            isDisabled={true}
                                            name="currency select"
                                            value={{
                                                value: updatedRate.mileage_details?.currency || "CAD",
                                                label: updatedRate.mileage_details?.currency || "CAD"
                                            }}
                                            options={[{value: "CAD", label: "CAD"}, {
                                                value: "USD", label: "USD"
                                            }, {value: "EUR", label: "EUR"},]}
                                            classNamePrefix="select"
                                            onChange={(choice) => setUpdatedRate({
                                                ...updatedRate, mileage_details: {
                                                    ...updatedRate.mileage_details, currency: choice.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{"Distance Unit"} :
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <Select
                                            styles={styleSelect}
                                            name="distance unit select"
                                            isDisabled={true}
                                            value={{
                                                value: updatedRate.mileage_details?.distance_unit || "km",
                                                label: updatedRate.mileage_details?.distance_unit || "km"
                                            }}
                                            options={[{value: "km", label: "km"}, {
                                                value: "miles", label: "miles"
                                            },]}
                                            classNamePrefix="select"
                                            onChange={(choice) => setUpdatedRate({
                                                ...updatedRate, mileage_details: {
                                                    ...updatedRate.mileage_details, distance_unit: choice.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{"Date"} :
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <input
                                            min="0001-01-01"
                                            max="9999-12-31"
                                            type="date"
                                            className="form-control"
                                            value={updatedRate.mileage_details?.date || ""}
                                            onChange={e => setUpdatedRate({
                                                ...updatedRate, mileage_details: {
                                                    ...updatedRate.mileage_details, date: e.target.value
                                                }
                                            })}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="form-group ">
                                <h4 className='mt-4'>{data.t.read("perdiem")} :</h4>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{data.t.read("rate")} :
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <MoneyInput
                                            value={updatedRate.perdiem_details?.rate || 0.0}
                                            onChange={(value) => setUpdatedRate({
                                                ...updatedRate, perdiem_details: {
                                                    ...updatedRate.perdiem_details, rate: value
                                                }
                                            })}
                                            locale={`${data.t.lang}-${data.t.lang.toUpperCase()}`}
                                            placeholder={data.t.read("rates")}
                                        />
                                    </div>
                                </div>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{data.t.read("currency")} :
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <Select
                                            styles={styleSelect}
                                            isDisabled={true}
                                            name="currency select"
                                            value={{
                                                value: updatedRate.perdiem_details?.currency || "CAD",
                                                label: updatedRate.perdiem_details?.currency || "CAD"
                                            }}
                                            options={[{value: "CAD", label: "CAD"}, {
                                                value: "USD", label: "USD"
                                            }, {value: "EUR", label: "EUR"},]}
                                            classNamePrefix="select"
                                            onChange={(choice) => setUpdatedRate({
                                                ...updatedRate, perdiem_details: {
                                                    ...updatedRate.perdiem_details, currency: choice.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='row pb-2 align-items-center '>
                                    <div
                                        className={`text-nowrap ${data.isMobile ? "col-3" : "col-2"}`}>{"Date"}:
                                    </div>
                                    <div className={data.isMobile ? "col-9" : "col-10"}>
                                        <input
                                            min="0001-01-01"
                                            max="9999-12-31"
                                            type="date"
                                            className="form-control"
                                            value={updatedRate.perdiem_details?.date || ""}
                                            onChange={e => setUpdatedRate({
                                                ...updatedRate, perdiem_details: {
                                                    ...updatedRate.perdiem_details, date: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 d-flex justify-content-between align-items-end">
                                <div>
                                    <button type="submit" className="btn btn-lg btn-primary"
                                            disabled={JSON.stringify(rates) === JSON.stringify(updatedRate)}>
                                        {data.t.read("save")}
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                {errorMessage != null && (<Modal title={data.t.read("attention")} okText={"OK"}
                                                 okAction={() => setErrorMessage(null)}>
                    <p style={{whiteSpace: 'pre-line'}} className='text-danger'>{errorMessage}</p>
                </Modal>)}
                {successMessage != null && (<Modal title={data.t.read("attention")} okText={"OK"}
                                                   okAction={() => setSuccessMessage(null)}>
                    {successMessage}
                </Modal>)}
            </div> : <div className="d-flex loading-container w-100">
                <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
            </div>}
        </div>
    </div>);
};

export default inject('data')(observer(ExpensesRates));