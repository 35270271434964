import React, {useEffect, useRef, useState} from 'react';

import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare, faPlus} from "@fortawesome/free-solid-svg-icons";
import Utils from '../utils/utils';

import {fetch} from '../utils/api-wrapper';
import Select from 'react-select';
import MoneyInput from "../components/money-input";

const Workflow = ({ data }) => {
    const [rules, setRules] = useState([]);
    const [rule, setRule] = useState(null);
    const [selectedRule, setSelectedRule] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [approvers, setApprovers] = useState([]);

    const cId = useRef(0);
    const aId = useRef(0);
    const valId = useRef(0);
    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    }

    const condition_types = [
        { value: "amount_lower", label: `...${data.t.read("rule.the_amount_is_lower")}...` },
        { value: "amount_greater", label: `...${data.t.read("rule.the_amount_is_greater")}...` },
        { value: "supplier_number", label: `...${data.t.read("rule.the_supplier_number_equals")}...` },
        { value: "supplier_number_not_equal", label: `...${data.t.read("rule.the_supplier_number_not_equals")}...` },
        { value: "cost_type", label: `...${data.t.read("rule.the_cost_type_equals")}...` },
        { value: "cost_type_not_equal", label: `...${data.t.read("rule.the_cost_type_not_equals")}...` },
        { value: "client_number", label: `...${data.t.read("rule.the_client_number_equals")}...` },
        { value: "gl_unit_number", label: `...${data.t.read("rule.the_gl_unit_number_equals")}...` },
        { value: "client_number_not_equal", label: `...${data.t.read("rule.the_client_number_not_equals")}...` },
        { value: "invoice_billable", label: `...${data.t.read("rule.invoice_is_set_as")}...` },
        { value: "invoice_custom", label: `...${data.t.read("rule.invoice_is_custom")}...` },
        { value: "supplier_name_equals", label: `...${data.t.read("rule.supplier_name_equals")}...` },
        { value: "gl_unit_is", label: `...${data.t.read("rule.gl_unit_is")}...` },
        { value: "gl_unit_is_not", label: `...${data.t.read("rule.gl_unit_is_not")}...` },
        { value: "user_has_role", label: `...${data.t.read("rule.user_has_role")}...` },
        { value: "line_item_amount", label: `...${data.t.read("rule.the_line_item_amount")}...` },
        { value: "item_description", label: `...${data.t.read("rule.item_description")}...` },
        { value: "item_cost_type", label: `...${data.t.read("rule.item_cost_type")}...` },
        { value: "item_gl", label: `...${data.t.read("rule.item_gl")}...` },
    ];

    const action_types = [
        { value: "send_email", label: `...${data.t.read("rule.send_email_to")}...` },
        { value: "split_items", label: `...${data.t.read("rule.split_items")}...` },
        { value: "only_keep_total", label: `...${data.t.read("rule.only_keep_total")}...` },
        { value: "change_currency", label: `...${data.t.read("rule.change_currency_for")}...` },
        { value: "change_all_totals", label: `...${data.t.read("rule.change_all_totals")}...` },
        { value: "change_user", label: `...${data.t.read("rule.change_user")}...` },
        { value: "change_item_description", label: `...${data.t.read("rule.change_item_description")}...` },
        { value: "change_item_detail", label: `...${data.t.read("rule.change_item_detail")}...` },
        { value: "change_status", label: `...${data.t.read("rule.change_status")}...` },
        { value: "achat_and_payment", label: `...${data.t.read("activate")} ${data.t.read("achat_and_payment").toLowerCase()}...` }, ...Utils.userHasPageAccess(data, "approval") ? [{
            value: "request_approval", label: `...${data.t.read("rule.request_approval")}...`
        }] : [],
    ];

    useEffect(() => {
        fetch("/rules", "GET", {},

            response => {

                let fetched_rules = response.data != null ? response.data : [];
                let ruleId = 1;

                fetched_rules.sort((a, b) => {
                    return (a.priority ?? 1) - (b.priority ?? 1)
                });

                fetched_rules.forEach(r => {
                    r.saved = true;
                    r.id = ruleId++;
                    r.actions.forEach(a => {
                        if (a["type"] === "split_items") {
                            a.value.forEach(v => {
                                if (valId.current < v.id) {
                                    valId.current = v.id + 1;
                                }
                            })
                        }
                    })

                    r.conditions.forEach(c => {
                        c.id = ++cId.current;
                    })

                    r.actions.forEach(a => {
                        a.id = ++aId.current;
                    })
                })


                setRules(fetched_rules);
                setSelectedRule(null);

            },
            error => {
                setErrorMessage("Error");
            })

        fetchApprovers()
    }, [])

    useEffect(() => {
    }, [selectedRule])

    const fetchApprovers = () => {
        fetch("/client/users/Approver", "GET", {},
            response => {
                setApprovers(response.data);
            })
    }


    const addRule = () => {
        let ruleId = 1;
        let updated_rules = rules;

        updated_rules.forEach(r => {
            if (ruleId <= r.id) {
                ruleId = r.id + 1;
            }
        })

        let new_rule = {
            active: true,
            id: ruleId,
            description: "",
            name: "<" + data.t.read("rule.rule").toLowerCase() + ">",
            conditions: [],
            actions: [],
            saved: false,
        };

        let c = {
            id: ++cId.current,
            type: "amount_greater",
            value: 1000,
        }
        new_rule.conditions.push(c);
        new_rule.saved = false;

        let a = {
            id: ++aId.current,
            type: "send_email",
            value: "",
        }
        new_rule.actions.push(a);
        new_rule.saved = false;


        updated_rules.push(new_rule);


        setRules(updated_rules);
        setSelectedRule(new_rule);
    }

    const addCondition = () => {
        let updated_rule = selectedRule;
        let c = {
            id: ++cId.current,
            type: "amount_greater",
            value: 1000,
        }
        updated_rule.conditions.push(c);
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const addAction = () => {
        let updated_rule = selectedRule;
        let a = {
            id: ++aId.current,
            type: "send_email",
            value: "",
        }
        updated_rule.actions.push(a);
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const updateRule = (property, event) => {
        let updated_rule = selectedRule;

        if (property === "active") {
            updated_rule.active = event.target.value === "1"
        }
        else if (property === "priority") {
            updated_rule.priority = parseInt(event.target.value)
        }
        else {
            updated_rule[property] = event.target.value;
        }

        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const updateCondition = (property, c, event) => {
        let updated_rule = selectedRule;

        updated_rule.conditions.forEach(t => {
            if (c.id === t.id) {
                t[property] = event.target.value;

                if (property === "type" && event.target.value === "invoice_billable") {
                    t["value"] = "1";
                }
                if (property === "type" && ["item_description", "item_gl", "item_cost_type"].includes(event.target.value)) {
                    t["value"] = "";
                    t["operator"] = "contain"
                }
                if (property === "type" && event.target.value === "line_item_amount") {
                    t["value"] = "";
                    t["operator"] = "greater"
                }
            }
        })
        updated_rule.saved = false;
        setRule({ ...updated_rule })
    }

    const removeCondition = (c) => {
        let updated_rule = selectedRule;
        updated_rule.conditions.splice(updated_rule.conditions.indexOf(c), 1);
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const updateAction = (property, a, event) => {
        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (a.id === t.id) {
                if (property === "type" && event.target.value === "split_items") {
                    if (!Array.isArray(t["value"])) {
                        let val = [getSplitItemValue()];
                        t["value"] = val;
                    }
                }
                else if (property === "type" && event.target.value === "change_item_detail") {
                    t['field'] = 'GLNatural'
                }

                else {
                    t["value"] = "";
                }

                t[property] = event.target.value;
            }
        })
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const removeAction = (c) => {
        let updated_rule = selectedRule;
        updated_rule.actions.splice(updated_rule.actions.indexOf(c), 1);
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const getSplitItemValue = () => {
        return {
            id: ++valId.current,
            percent: 100,
            updateInfo: "",
            updateInfoValue: "",
            updateCharacterAmount: null,
            replaceAtTheStart: false,
            maxAmount: ""
        }
    }

    const addSplitItemValue = (action) => {
        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (action.id === t.id) {
                let val = t.value;
                val.push(getSplitItemValue());
                t["value"] = val;
            }
        })
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const updateSplitItemValue = (action, line, property, e) => {
        var str2bool = (value) => {
            if (value && typeof value === "string") {
                if (value.toLowerCase() === "true") return true;
                if (value.toLowerCase() === "false") return false;
            }
            return value;
        }

        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (action.id === t.id) {
                t["value"].forEach(v => {
                    if (v === line) {
                        v[property] = str2bool(e.target.value);
                    }
                })
            }
        })
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const removeSplitItemValue = (action, line) => {
        let updated_rule = selectedRule;

        updated_rule.actions.forEach(t => {
            if (action.id === t.id) {
                let val = t.value;
                val = val.filter(v => line !== v);
                t["value"] = val;
            }
        })
        updated_rule.saved = false;

        setRule({ ...updated_rule })
    }

    const handleSaveRule = (event) => {
        event.preventDefault();

        let data = new FormData();
        let tmp = JSON.stringify(selectedRule);
        tmp = JSON.parse(tmp);
        delete tmp.saved
        tmp.conditions.forEach(c => {
            delete c.id
        })
        tmp.actions.forEach(a => {
            delete a.id
        })
        data.append("rule", JSON.stringify(tmp));

        fetch("/rule", "POST", data,

            response => {
                let rule = selectedRule;
                rule.saved = true;
                if (!rule._id && response.data)
                    rule._id = response.data

                setRule(rule);
            },
            error => {
                setErrorMessage("Error")
            })
    }

    const deleteRule = () => {
        const deleleFromList = () => {
            let rules_copy = rules;
            rules_copy.splice(rules.indexOf(selectedRule), 1);

            setRules(rules_copy);
            setSelectedRule(null);
        }

        if (window.confirm("Delete?")) {
            if (selectedRule._id != null) {

                fetch("/rule?id=" + selectedRule._id, "DELETE", {},

                    response => {
                        deleleFromList();
                    },
                    error => {
                        setErrorMessage("Error")
                    })
            }
            else {
                deleleFromList();
            }
        }
    }

    const approverMap = (approver) => {
        return {
            value: approver?.id_user, label: `${approver.first_name} ${approver.last_name}`
        }
    };


    return (
        <div className="card pl-4 pr-4 pb-3 rounded-lg">
            {
                rules != null ?
                    <div className="workflow-page row">
                        <div id="workflow-rules">
                            {
                                rules.map(r => {
                                    return <button key={r.id}
                                        className={"btn btn-secondary " + (r.active ? "" : " faded ") + (selectedRule != null && selectedRule.id === r.id ? "btn-info" : "")}
                                        onClick={() => setSelectedRule(r)}>
                                        {r.name} {!r.saved ? "*" : ""}
                                    </button>
                                })
                            }
                            <button className="btn btn-primary add-rule"
                                onClick={() => addRule()}>+ {data.t.read("rule.rule")}</button>
                        </div>
                        <div id="workflow-diagram">
                            <div className="alert-section">
                                {
                                    errorMessage != null ?
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <form className="" method="post" onSubmit={(e) => {
                                handleSaveRule(e)
                            }}>
                                {
                                    selectedRule != null ?
                                        <div id="rule-container">
                                            <div>
                                                <div className="mb-2">
                                                    <div className=" row">
                                                        <label className="col-2">
                                                            {data.t.read("rule.name")}:
                                                        </label>
                                                        <div className="col-4">
                                                            <input type="text" onFocus={e => e.target.select()} required
                                                                className="form-control"
                                                                placeholder={data.t.read("rule.name")}
                                                                value={selectedRule.name}
                                                                onChange={(e) => updateRule("name", e)} />
                                                        </div>
                                                    </div>

                                                    <div className=" row">
                                                        <label className="col-2">
                                                            {data.t.read("rule.status")}:
                                                        </label>
                                                        <div className="col-2">
                                                            <Select
                                                                styles={styleSelect}
                                                                name="active select"
                                                                value={{
                                                                    value: selectedRule.active ? "1" : "0",
                                                                    label: data.t.read(selectedRule.active ? "rule.active" : "rule.inactive")
                                                                }}
                                                                options={[
                                                                    { value: "1", label: data.t.read("rule.active") },
                                                                    { value: "0", label: data.t.read("rule.inactive") },
                                                                ]}
                                                                classNamePrefix="select"
                                                                onChange={(choice) => updateRule("active", { target: { value: choice.value } })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className=" row">
                                                        <label className="col-2">
                                                            {data.t.read("rule.priority")}:
                                                        </label>
                                                        <div className="col-1">
                                                            <input type="number" min="1" max="100"
                                                                className="form-control"
                                                                value={selectedRule.priority ?? 1}
                                                                onChange={e => updateRule("priority", e)} />
                                                        </div>
                                                    </div>

                                                    <div className=" row">
                                                        <label className="col-2">
                                                            Description :
                                                        </label>
                                                        <div className="col-6">
                                                            <textarea type="text" required className="form-control"
                                                                placeholder="Description"
                                                                value={selectedRule.description}
                                                                onChange={(e) => updateRule("description", e)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3>
                                                    Conditions
                                                    <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1'
                                                        type="button"
                                                        onClick={() => addCondition()}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </h3>
                                                <div className="mb-1">
                                                    {data.t.read("rule.after_invoice_complete")}...
                                                </div>
                                                {
                                                    selectedRule.conditions.map((c, idx) => {
                                                        return <div key={"c-" + c.id} className="mb-2">
                                                            <div className=" row">
                                                                <h5 className=" pt-2 col-1 d-flex ">
                                                                    <div className={"w-25 mr-2"}>
                                                                        {selectedRule.conditions.length > 1 &&
                                                                            <FontAwesomeIcon
                                                                                className=""
                                                                                icon={faMinusSquare} type="button"
                                                                                color={"red"}
                                                                                onClick={() => removeCondition(c)}
                                                                            />}
                                                                    </div>
                                                                    <div className={"mr-1"}>
                                                                        {
                                                                            idx > 0 && selectedRule.conditions.length > 1 ?
                                                                                data.t.read("rule.and") + " "
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {data.t.read("rule.if")}...
                                                                    </div>


                                                                </h5>
                                                                <div className="col-4">
                                                                    <Select
                                                                        styles={styleSelect}
                                                                        name="condition type select"
                                                                        value={condition_types.find(option => option.value === c.type) || ''}
                                                                        options={condition_types}
                                                                        classNamePrefix="select"
                                                                        onChange={(choice) => updateCondition('type', c, { target: { value: choice.value } })}
                                                                    />
                                                                </div>
                                                                <div className="col-7 row">
                                                                    {
                                                                    c.type === "item_description" &&
                                                                        <div className='col-3'>
                                                                            <Select
                                                                                styles={styleSelect}
                                                                                name="operator select"
                                                                                value={{
                                                                                    value: c.operator,
                                                                                    label: `${data.t.read(`rule.${c.operator}`)}`
                                                                                }}
                                                                                options={[
                                                                                    { value: "contain", label: `${data.t.read("rule.contain")}` },
                                                                                    { value: "not_contain", label: `${data.t.read("rule.not_contain")}` },
                                                                                    { value: "equal", label: `${data.t.read("rule.equal")}` },
                                                                                    { value: "different", label: `${data.t.read("rule.different")}` },
                                                                                ]}
                                                                                classNamePrefix="select"
                                                                                onChange={(choice) => updateCondition('operator', c, { target: { value: choice.value } })}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        c.type === "line_item_amount" &&
                                                                        <div className='col-3'>
                                                                            <Select
                                                                                styles={styleSelect}
                                                                                name="operator select"
                                                                                value={{
                                                                                    value: c.operator,
                                                                                    label: `${data.t.read(`rule.${c.operator}`)}`
                                                                                }}
                                                                                options={[
                                                                                    { value: "greater", label: `${data.t.read("rule.greater")}` },
                                                                                    { value: "lower", label: `${data.t.read("rule.lower")}` },
                                                                                    { value: "equals", label: `${data.t.read("rule.equals")}` },
                                                                                ]}
                                                                                classNamePrefix="select"
                                                                                onChange={(choice) => updateCondition('operator', c, { target: { value: choice.value } })}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                    c.type === "item_cost_type" &&
                                                                        <div className='col-3'>
                                                                            <Select
                                                                                styles={styleSelect}
                                                                                name="operator cost type select"
                                                                                value={{
                                                                                    value: c.operator,
                                                                                    label: `${data.t.read(`rule.${c.operator}`)}`
                                                                                }}
                                                                                options={[
                                                                                    { value: "contain", label: `${data.t.read("rule.contain")}` },
                                                                                    { value: "not_contain", label: `${data.t.read("rule.not_contain")}` },
                                                                                    { value: "equal", label: `${data.t.read("rule.equal")}` },
                                                                                    { value: "different", label: `${data.t.read("rule.different")}` },
                                                                                ]}
                                                                                classNamePrefix="select"
                                                                                onChange={(choice) => updateCondition('operator', c, { target: { value: choice.value } })}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                    c.type === "item_gl" &&
                                                                        <div className='col-3'>
                                                                            <Select
                                                                                styles={styleSelect}
                                                                                name="operator gl select"
                                                                                value={{
                                                                                    value: c.operator,
                                                                                    label: `${data.t.read(`rule.${c.operator}`)}`
                                                                                }}
                                                                                options={[
                                                                                    { value: "contain", label: `${data.t.read("rule.contain")}` },
                                                                                    { value: "not_contain", label: `${data.t.read("rule.not_contain")}` },
                                                                                    { value: "equal", label: `${data.t.read("rule.equal")}` },
                                                                                    { value: "different", label: `${data.t.read("rule.different")}` },
                                                                                ]}
                                                                                classNamePrefix="select"
                                                                                onChange={(choice) => updateCondition('operator', c, { target: { value: choice.value } })}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <div className="col-5">
                                                                        {
                                                                            c.type === "invoice_billable" ?
                                                                                <div>
                                                                                    <Select
                                                                                        styles={styleSelect}
                                                                                        name="value select"
                                                                                        value={{
                                                                                            value: c.value,
                                                                                            label: data.t.read(c.value === "1" ? "rule.billable" : "rule.non_billable")
                                                                                        }}
                                                                                        options={[
                                                                                            { value: "1", label: data.t.read("rule.billable") },
                                                                                            { value: "0", label: data.t.read("rule.non_billable") },
                                                                                        ]}
                                                                                        classNamePrefix="select"
                                                                                        onChange={(choice) => updateCondition('value', c, { target: { value: choice.value } })}
                                                                                    />
                                                                                </div>

                                                                                :
                                                                                c.type === "user_has_role" ?
                                                                                <div>
                                                                                    <Select
                                                                                        styles={styleSelect}
                                                                                        name="user_has_role"
                                                                                        value={c.role ? {
                                                                                            value: c.role,
                                                                                            label: data.t.read("role_" + c.role.toLowerCase())
                                                                                        } : null}
                                                                                        options={data.config.roles.map(r => ({
                                                                                            value: r,
                                                                                            label: data.t.read("role_" + r.toLowerCase())
                                                                                          }))}
                                                                                          placeholder={data.t.read('client_table.role').toLowerCase()}
                                                                                        classNamePrefix="select"
                                                                                        onChange={(choice) => updateCondition('role', c, { target: { value: choice.value } })}
                                                                                    />
                                                                                </div>

                                                                                :
                                                                                c.type === "invoice_custom" ?
                                                                                    <Select
                                                                                        styles={styleSelect}
                                                                                        name="invoice custom select"
                                                                                        value={{
                                                                                            value: c.value,
                                                                                            label: data.t.read(c.value === "1" ? "rule.invoice_custom" : "rule.invoice_not_custom")
                                                                                        }}
                                                                                        options={[
                                                                                            { value: "1", label: data.t.read("rule.invoice_custom") },
                                                                                            { value: "0", label: data.t.read("rule.invoice_not_custom") },
                                                                                        ]}
                                                                                        classNamePrefix="select"
                                                                                        onChange={(choice) => updateCondition('value', c, { target: { value: choice.value } })}
                                                                                    />
                                                                                    :
                                                                                    c.type === "line_item_amount" ?
                                                                                        <MoneyInput value={c.value}

                                                                                                    onChange={(value) => updateCondition("value", c, { target: { value: value } })}
                                                                                                    locale={`${data.t.lang}-${data.t.lang.toUpperCase()}`}
                                                                                                    placeholder={data.t.read("rule.amount")}/>
                                                                                        :
                                                                                        <input type="text"
                                                                                               className="form-control"
                                                                                               placeholder={data.t.read("rule.value")}
                                                                                               value={c.value}
                                                                                               onChange={(e) => updateCondition("value", c, e)} />
                                                                        }
                                                                    </div>
                                                                    <label className="col-7 font-xsmall">
                                                                        {
                                                                            c.type === "supplier_number" ?
                                                                                "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                                :
                                                                                c.type === "gl_unit_number" ?
                                                                                    "Ex: 14, or a list : 4, A, 14, Montreal"
                                                                                    :
                                                                                    c.type === "cost_type" || c.type === "cost_type_not_equal" ?
                                                                                        "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                                        :
                                                                                        c.type === "client_number" || c.type === "client_number_not_equal" ?
                                                                                            "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                                            :
                                                                                            null
                                                                        }
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                                <h3>
                                                    Actions:
                                                    <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1'
                                                        type="button"
                                                        onClick={() => addAction()}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </h3>
                                                {
                                                    selectedRule.actions.map((c, idx) => {
                                                        return <div key={"a-" + c.id} className="mb-1">
                                                            <div className="workflow-line row ">

                                                                <h5 className="col-1 d-flex pt-2">
                                                                    <div className={"w-25 mr-2"}>
                                                                        {selectedRule.actions.length > 1 &&
                                                                            <FontAwesomeIcon
                                                                                className=""
                                                                                icon={faMinusSquare} type="button"
                                                                                color={"red"}
                                                                                onClick={() => removeAction(c)}
                                                                            />}
                                                                    </div>
                                                                    <div className={"align-text-center"}>
                                                                        {
                                                                            idx > 0 && selectedRule.actions.length > 1 ?
                                                                                data.t.read("rule.and")
                                                                                :
                                                                                data.t.read("rule.then")
                                                                        }
                                                                    </div>
                                                                </h5>
                                                                <div className="col-4">
                                                                    <Select
                                                                        styles={styleSelect}
                                                                        isDisabled={c.type === "request_approval" && !Utils.userHasPageAccess(data, "approval")}
                                                                        name="action type select"
                                                                        value={action_types.find(option => option.value === c.type) || {
                                                                            value: c.type,
                                                                            label: `...${data.t.read(`rule.${c.type}`)}...`
                                                                        }}
                                                                        options={action_types}
                                                                        classNamePrefix="select"
                                                                        onChange={(choice) => updateAction('type', c, { target: { value: choice.value } })}
                                                                    />
                                                                </div>
                                                                {
                                                                    c.type === "achat_and_payment" &&
                                                                    <div className="col-6 border p-2">

                                                                        <div className='row'>
                                                                            <div className='col-3 align-self-center'>
                                                                                {data.t.read('payment_mode')}
                                                                            </div>
                                                                            <div className='col-9'>
                                                                                <Select
                                                                                    styles={styleSelect}
                                                                                    name="payment_mode"
                                                                                    value={c.payment_mode ?? ''}
                                                                                    options={data.config.payment_modes ?
                                                                                        data.config.payment_modes.map(p => ({ value: p.id, label: p.name }))
                                                                                        : []
                                                                                    }
                                                                                    placeholder={data.t.read('payment_mode')}
                                                                                    classNamePrefix="select"
                                                                                    onChange={(choice) => updateAction('payment_mode', c, { target: { value: choice } })}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='row'>
                                                                            <div className='col-3 align-self-center'>
                                                                                {data.t.read('bank')}
                                                                            </div>
                                                                            <div className='col-9'>
                                                                                <Select
                                                                                    styles={styleSelect}
                                                                                    name="bank"
                                                                                    value={c.bank ?? ''}
                                                                                    options={data.config.banks ?
                                                                                        data.config.banks.map(p => ({ value: p.key, label: p.value }))
                                                                                        : []
                                                                                    }
                                                                                    placeholder={data.t.read('bank')}
                                                                                    classNamePrefix="select"
                                                                                    onChange={(choice) => updateAction('bank', c, { target: { value: choice } })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    c.type === "request_approval" &&
                                                                    <div>
                                                                        <Select
                                                                            styles={styleSelect}
                                                                            isDisabled={c.type === "request_approval" && !Utils.userHasPageAccess(data, "approval")}
                                                                            name="approvers"
                                                                            value={approvers.filter(a => a.id_user === c.value).map(approverMap) ?? null}
                                                                            options={approvers.map(approverMap)}
                                                                            placeholder={data.t.read('approver')}
                                                                            classNamePrefix="select"
                                                                            onChange={(choice) => updateAction('value', c, {target: {value: choice.value}})}
                                                                        />

                                                                    </div>
                                                                }
                                                                {
                                                                    c.type === "change_status" &&
                                                                    <div className='col-2'>
                                                                        <Select
                                                                            styles={styleSelect}
                                                                            name="invoice status select"
                                                                            value={{
                                                                                value: c.value,
                                                                                label: data.t.read("status." + c.value.toLowerCase())
                                                                            }}
                                                                            options={data.config.invoice_status.map(tmp => ({
                                                                                value: tmp,
                                                                                label: data.t.read("status." + tmp.toLowerCase())
                                                                            }))}
                                                                            classNamePrefix="select"
                                                                            onChange={(choice) => updateAction('value', c, { target: { value: choice.value } })}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    c.type === "change_item_detail" &&
                                                                    <>
                                                                        <div className='col-2'>
                                                                            <Select
                                                                                styles={styleSelect}
                                                                                name="field select"
                                                                                value={{
                                                                                    value: c.field,
                                                                                    label: c.field === "Description" ? "Description" : data.t.read(c.field === "GLNatural" ? "gl_natural" : c.field === "GLOffice" ? "office" : "invoice.cost_type"),
                                                                                }}
                                                                                options={[
                                                                                    { value: "GLNatural", label: data.t.read("gl_natural") },
                                                                                    { value: "GLOffice", label: data.t.read("office") },
                                                                                    { value: "CostType", label: data.t.read("invoice.cost_type") },
                                                                                    { value: "Description", label: "Description" },
                                                                                ]}
                                                                                classNamePrefix="select"
                                                                                onChange={(choice) => updateAction("field", c, { target: { value: choice.value } })}
                                                                            />
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <input type="text" required
                                                                                className="form-control"
                                                                                placeholder={c.field} value={c.value}
                                                                                onChange={(e) => updateAction("value", c, e)} />
                                                                        </div>
                                                                    </>

                                                                }
                                                                {
                                                                    c.type === "send_email" &&
                                                                    <div className="col-7 row">
                                                                        <div className="col-4">
                                                                            <input type="email" required
                                                                                className="form-control"
                                                                                placeholder={data.t.read("rule.email")}
                                                                                value={c.value}
                                                                                onChange={(e) => updateAction("value", c, e)} />
                                                                        </div>
                                                                        <label className="col-8 font-xsmall">
                                                                            Ex: me@my-company.com
                                                                        </label>
                                                                    </div>
                                                                }

                                                                {c.type === "change_currency" &&
                                                                    <div className="col-7 row">
                                                                        <div className="col-4">
                                                                            <input type="text" required
                                                                                className="form-control"
                                                                                placeholder={data.t.read("rule.currency")}
                                                                                value={c.value}
                                                                                onChange={(e) => updateAction("value", c, e)} />
                                                                        </div>
                                                                        <label className="col-8 font-xsmall">
                                                                            Ex: CAD
                                                                        </label>
                                                                    </div>
                                                                }
                                                                {
                                                                    c.type === "split_items" &&
                                                                    <div className="col-7">
                                                                        <div>
                                                                            {
                                                                                c.value.map((line, idx) => {
                                                                                    return <div key={"l-" + line.id}
                                                                                        className="d-flex mb-2  p-1">
                                                                                        <div className={""}>
                                                                                            {c.value.length > 1 &&
                                                                                                <FontAwesomeIcon
                                                                                                    className=""
                                                                                                    icon={faMinusSquare}
                                                                                                    type="button"
                                                                                                    color={"red"}
                                                                                                    onClick={() => removeSplitItemValue(c, line)}
                                                                                                />}
                                                                                        </div>

                                                                                        <div className="ml-1 row border p-1">
                                                                                            <div>
                                                                                                <div className="row mb-1">
                                                                                                    <div className="col-3 font-weight-bold">
                                                                                                        {
                                                                                                            idx === 0 ?
                                                                                                                data.t.read("rule.a_line_containing")
                                                                                                                :
                                                                                                                data.t.read("rule.and_add_line")
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        <div
                                                                                                            className="input-group input-group-sm">
                                                                                                            <div
                                                                                                                className="input-group-prepend">
                                                                                                                <span
                                                                                                                    className="input-group-text">%</span>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                min="-100"
                                                                                                                max="1000"
                                                                                                                value={line.percent}
                                                                                                                onChange={e => updateSplitItemValue(c, line, "percent", e)}
                                                                                                                style={{ maxWidth: "100%" }}
                                                                                                                className="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        {data.t.read("rule.of_amount")},
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row mb-1">
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        {data.t.read("rule.up_to_max_of")}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        <div
                                                                                                            className="input-group input-group-sm">
                                                                                                            <div
                                                                                                                className="input-group-prepend">
                                                                                                                <span
                                                                                                                    className="input-group-text">$</span>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                value={line.maxAmount}
                                                                                                                style={{ maxWidth: "100%" }}
                                                                                                                onChange={e => updateSplitItemValue(c, line, "maxAmount", e)}
                                                                                                                className="form-control" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-5">
                                                                                                        ({data.t.read("rule.leave_empty_for_unlimited")})
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row mb-1">
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        {data.t.read("rule.and_replace")}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            min="-100"
                                                                                                            max="1000"
                                                                                                            value={line.updateCharacterAmount}
                                                                                                            onChange={e => updateSplitItemValue(c, line, "updateCharacterAmount", e)}
                                                                                                            style={{ maxWidth: "100%" }}
                                                                                                            className="form-control" />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        {data.t.read("rule.character_at")}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        <Select
                                                                                                            styles={styleSelect}
                                                                                                            name="replace at the start select"
                                                                                                            value={{
                                                                                                                value: line.replaceAtTheStart,
                                                                                                                label: data.t.read(line.replaceAtTheStart ? "rule.the_start" : "rule.the_end")
                                                                                                            }}
                                                                                                            options={[
                                                                                                                { value: false, label: data.t.read("rule.the_end") },
                                                                                                                { value: true, label: data.t.read("rule.the_start") },
                                                                                                            ]}
                                                                                                            classNamePrefix="select"
                                                                                                            onChange={(choice) => updateSplitItemValue(c, line, "replaceAtTheStart", { target: { value: choice.value } })}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row mb-1">
                                                                                                    <div
                                                                                                        className="col-3">
                                                                                                        {data.t.read("rule.of")}
                                                                                                    </div>
                                                                                                    <div className="col-3">
                                                                                                        <Select
                                                                                                            styles={styleSelect}
                                                                                                            name="update info select"
                                                                                                            value={{
                                                                                                                value: line.updateInfo || "",
                                                                                                                label: line.updateInfo || "--"
                                                                                                            }}
                                                                                                            options={[
                                                                                                                { value: "", label: "--" },
                                                                                                                { value: "GLNatural", label: "GLNatural" },
                                                                                                                { value: "GLOffice", label: "GLOffice" },
                                                                                                                { value: "CostType", label: "CostType" },
                                                                                                            ]}
                                                                                                            classNamePrefix="select"
                                                                                                            onChange={(choice) => updateSplitItemValue(c, line, "updateInfo", { target: { value: choice.value } })}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {
                                                                                                        line.updateInfo != null && line.updateInfo.length > 0 &&
                                                                                                        <React.Fragment>
                                                                                                            <div
                                                                                                                className="col-3">
                                                                                                                {data.t.read("rule.by")}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-3">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    required
                                                                                                                    className="form-control form-control-sm"
                                                                                                                    placeholder="Value"
                                                                                                                    value={line.updateInfoValue}
                                                                                                                    onChange={e => updateSplitItemValue(c, line, "updateInfoValue", e)} />
                                                                                                            </div>
                                                                                                        </React.Fragment>

                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <button type="button"
                                                                                className="btn btn-success"
                                                                                onClick={() => addSplitItemValue(c)}>
                                                                                + {data.t.read("rule.line")}
                                                                                {/*{data.t.read("rule.add_line")}*/}
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                }
                                                                {
                                                                    c.type === "change_all_totals" &&
                                                                    <div className="input-group-prepend">
                                                                        <input type="number" min="-100" max="100"
                                                                            value={c.value}
                                                                            onChange={e => updateAction("value", c, e)}
                                                                            style={{ maxWidth: "100%" }}
                                                                            className="form-control" />
                                                                        <span className="input-group-text">%</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    c.type === "change_user" &&
                                                                    <div className="input-group-prepend">
                                                                        <input type="text" value={c.value}
                                                                            onChange={e => updateAction("value", c, e)} />
                                                                    </div>
                                                                }
                                                                {
                                                                    c.type === "change_item_description" &&
                                                                    <div className='col-4'>
                                                                        <input type="text" value={c.value}
                                                                            className="form-control"
                                                                            placeholder="Description"
                                                                            onChange={e => updateAction("value", c, e)} />
                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className="mt-5 d-flex justify-content-between align-items-end">
                                                <div>
                                                    <button type="button" className="btn btn-sm btn-danger"
                                                        onClick={() => deleteRule()}>{data.t.read("rule.delete_rule")}</button>
                                                </div>
                                                <div>
                                                    <button type="submit" disabled={selectedRule.saved}
                                                        className="btn btn-lg btn-primary">{data.t.read("rule.save_rule")}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </form>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default inject('data')(observer(Workflow));