import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faSpinner, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../components/checkbox";
import Modal from "../components/modal";
import ExpenseNavigationBar from "../components/expense_configuration-navigation-bar";
import {fetch} from "../utils/api-wrapper";
import FilterInputField from "../components/filter-input-field";
import PaginationControls from "../components/pagination-controls";

const ExpensesImputations = ({data}) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [imputations, setImputations] = useState([]);
    const [showImputationModal, setShowImputationModal] = useState(false);
    const [updatedImputation, setUpdatedImputation] = useState({});
    const [imputationsDeleteSelection, setImputationsDeleteSelection] = useState([]);
    const [showDeleteImputationModal, setShowDeleteImputationModal] = useState(false);
    const [showAddDefaultImputationsModal, setShowAddDefaultImputationsModal] = useState(false);

    const [pageSize, setPageSize] = useState(10);
    const [filterValue, setFilterValue] = useState("");
    const [sorting, setSorting] = useState([{"id": "names", "desc": false}]);
    const [page, setPage] = useState(1);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const availableColumns = [
        {"name": "category", "evaluate": imputation => imputation.category, "displayName": "category"},
        {"name": "gl_natural", "evaluate": imputation => imputation.gl_natural, "displayName": "gl_natural"},
        ...(data?.config?.type === "legal" ? [
            {"name": "cost_type", "evaluate": imputation => imputation.cost_type, "displayName": "cost_type"},
        ] : [])
    ]

    useEffect(() => {
        refreshImputations()
    }, [])

    useEffect(() => {
        setPage(1);
        refreshImputations();
    }, [pageSize, sorting, filterValue]);

    useEffect(() => {
        refreshImputations();
    }, [page]);


    const refreshImputations = () => {
        let start = (page - 1) * pageSize;
        const filters = []
        if (filterValue && filterValue.trim() !== "") {
            filters.push({id: "search", value: filterValue.trim()});
        }
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(start)}&page_size=${pageSize}`;
        fetch(`/imputations/${searchData}`, "GET", {},
            response => {
                let fetchedImputations = response.data.imputations;
                setImputations(fetchedImputations);
                setTotalRowCount(response.data.meta.totalRowCount);
                setLoading(false)
            });
    }

    const handleImputationSubmit = (e) => {
        e.preventDefault();
        setShowImputationModal(false)
        if (updatedImputation._id) {
            updateImputation()
        } else {
            createImputation()
        }
    };


    const createImputation = () => {
        let formData = new FormData();
        formData.append("imputation", JSON.stringify(updatedImputation));

        fetch("/imputations/", "POST", formData,
            () => {
                setSuccessMessage(data.t.read("imputation_created_successfully"));
                refreshImputations();
            },
            () => {
                setErrorMessage(data.t.read("imputation_creation_failure"))
                setLoading(false);
            }
        );
    };

    const addDefaultImputations = () => {
        fetch("/imputations/add-defaults", "POST", {},
            () => {
                setShowAddDefaultImputationsModal(false);
                setSuccessMessage(data.t.read("default_imputations_success"));
                refreshImputations();
            },
            () => {
                setShowAddDefaultImputationsModal(false);
                setErrorMessage(data.t.read("default_imputations_failure"))
                setLoading(false);
            }
        );
    };


    const updateImputation = () => {
        const updatedImputationWithoutId = {...updatedImputation}
        delete updatedImputationWithoutId._id;
        let formData = new FormData();
        formData.append("imputation", JSON.stringify(updatedImputationWithoutId));
        const imputationId = updatedImputation._id?.$oid;
        fetch(`/imputations/${imputationId}`, "PUT", formData,
            () => {
                refreshImputations()
                setSuccessMessage(data.t.read("update_successful"))
            },
            () => {
                setErrorMessage(data.t.read("failed_to_update"))
                setLoading(false);
            }
        )
    };

    const openNewImputationModal = () => {
        setUpdatedImputation(
            {
                category: "",
                gl_natural: "",
                id_client: data.id_client,
                cost_type: ""
            });
        setShowImputationModal(true);
    };

    const openUpdateImputationModal = (imputation) => {
        setUpdatedImputation({...imputation});
        setShowImputationModal(true)
    }

    const cancelImputationModal = () => {
        setUpdatedImputation({});
        setShowImputationModal(false);
    }

    const deleteImputations = () => {
        fetch("/imputations/", "DELETE", { "imputation_ids": imputationsDeleteSelection },
            () => {
                refreshImputations();
                setImputationsDeleteSelection([])
                setShowDeleteImputationModal(false)
            },
            (error) => {
                setErrorMessage(data.t.read("failed_to_delete"))
                setLoading(false);
                setShowDeleteImputationModal(false)
            }
        );
    }

    const cancelDeleteImputationModal = () => {
        setShowDeleteImputationModal(false);
        setImputationsDeleteSelection([])
    }

    const isAllImputationsSelected = () => {
        if (!imputations || !imputations.length) {
            return false;
        }
        return imputations.every(imputation => imputationsDeleteSelection.includes(imputation._id?.$oid));
    }

    const isImputationSelected = (imputation) => {
        return imputationsDeleteSelection.includes(imputation._id?.$oid);
    }

    const toggleAllImputationsSelected = () => {
        setImputationsDeleteSelection(isAllImputationsSelected() ?
            imputationsDeleteSelection.filter(id => !imputations.some(imputation => imputation._id?.$oid === id))
            :
            [...imputationsDeleteSelection, ...imputations.map((imputation) => imputation._id?.$oid)]
        );
    }

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (rows) => {
        setPage(1)
        setPageSize(rows)
    }

    //pagination logic
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + imputations.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - imputations.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    return (

        <div className="card pl-4 pr-4 pb-3 rounded-lg ">
            <ExpenseNavigationBar data={data} page={"imputations"}></ExpenseNavigationBar>
            <div>
                {!loading ?
                    <div>
                        <div>
                            <div className='pagination-row '>
                                <FilterInputField
                                    filterValue={filterValue} setFilterValue={setFilterValue} placeholder={""}>
                                </FilterInputField>
                                <PaginationControls
                                    page={page}
                                    totalPages={totalPages}
                                    indexOfFirstItem={indexOfFirstItem}
                                    indexOfLastItem={indexOfLastItem}
                                    handlePageChange={handlePageChange}
                                    totalItems={totalRowCount}
                                    data={data}
                                    handleRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPage={pageSize}
                                ></PaginationControls>
                            </div>
                            <div className="d-flex justify-content-between align-items-center py-2">
                                <div className="d-flex">
                                    <button
                                        onClick={() => setShowAddDefaultImputationsModal(true)}
                                        type="button"
                                        className="btn btn-secondary btn-sm ml-2">
                                        {data.t.read("reset")}
                                    </button>
                                </div>
                                <div className="d-flex">

                                    <button
                                        disabled={imputationsDeleteSelection.length === 0}
                                        onClick={() => setShowDeleteImputationModal(true)}
                                        type="button"
                                        className="btn btn-secondary btn-sm ml-2">
                                        {data.t.read("delete") + " (" + imputationsDeleteSelection.length + ")"}
                                    </button>
                                    <button
                                        onClick={() => openNewImputationModal()}
                                        type="button"
                                        className='btn btn-primary btn-sm ml-2 justify-content-end'>
                                        {data.t.read("add")}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-2 card rounded-lg admin-table">
                                <table className='table table-hover font-small'>
                                    <thead>
                                    <tr>
                                        <th key={`checkbox`}>
                                            <Checkbox
                                                disabled={!imputations || !imputations.length}
                                                isChecked={isAllImputationsSelected()}
                                                onChange={toggleAllImputationsSelected}/>
                                        </th>
                                        {availableColumns.map((availableColumn) =>
                                            <th className="col-4" key={availableColumn.name}>
                                                {data.t.read(availableColumn.displayName)}
                                            </th>
                                        )}
                                        <th className="col-1" key={"actions"}>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        imputations.map((imputation, key = imputation._id) => {
                                            return <tr key={imputation._id?.$oid}>
                                                <td key={`checkbox`}>
                                                    <Checkbox
                                                        isChecked={isImputationSelected(imputation)}
                                                        onChange={() => isImputationSelected(imputation) ? setImputationsDeleteSelection(imputationsDeleteSelection.filter(x => x !== imputation._id?.$oid)) : setImputationsDeleteSelection([...imputationsDeleteSelection, imputation._id?.$oid])}/>
                                                </td>
                                                {availableColumns.map((availableColumn) =>
                                                    <td key={availableColumn.name}
                                                        style={{width: (`${100 / availableColumns.length}%`)}}
                                                        onClick={() => openUpdateImputationModal(imputation)}>
                                                        {availableColumn.evaluate(imputation)}
                                                    </td>
                                                )}
                                                <td key={"actions"}>
                                                    {<FontAwesomeIcon
                                                        className="mr-2"
                                                        icon={faPencilAlt}
                                                        type="button"
                                                        onClick={() => openUpdateImputationModal(imputation)}
                                                    />}
                                                    {<FontAwesomeIcon
                                                        onClick={() => {
                                                            setShowDeleteImputationModal(true);
                                                            setImputationsDeleteSelection([imputation._id?.$oid]);
                                                        }}
                                                        icon={faWindowClose}
                                                        type="button"
                                                        style={{color: 'red'}}/>}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    {
                                        emptyRows.map((_, idx) => (
                                            <tr key={`empty-${idx}`}>
                                                <td colSpan="8">&nbsp;</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {errorMessage != null && (
                            <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>

                                <p style={{whiteSpace: 'pre-line'}} className='text-danger'>{errorMessage}</p>
                            </Modal>
                        )}
                        {successMessage != null && (
                            <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                                {successMessage}
                            </Modal>
                        )}
                        {showImputationModal && (
                            <form method="post" onSubmit={handleImputationSubmit}>
                                <Modal
                                    title="Imputation"
                                    okIsSubmit={true}
                                    cancelAction={cancelImputationModal}
                                    okText={data.t.read("save")}
                                    cancelText={data.t.read("cancel")}>
                                    {availableColumns.map(availableColumn =>
                                        <div className='mb-4 d-flex align-items-center'>
                                            <label className="col-4">
                                                {data.t.read(availableColumn.displayName)}
                                            </label>
                                            <input className="form-control" type="text"
                                                   value={updatedImputation[availableColumn.name] || ""}
                                                   onChange={(e) => setUpdatedImputation({
                                                       ...updatedImputation,
                                                       [availableColumn.name]: e.target.value
                                                   })}
                                            />
                                        </div>
                                    )}
                                </Modal>
                            </form>
                        )}
                        {showDeleteImputationModal && (
                            <Modal
                                title="Delete"
                                okAction={deleteImputations}
                                cancelAction={cancelDeleteImputationModal}
                                okText={data.t.read("delete")}
                                cancelText={data.t.read("cancel")}>
                                Delete

                            </Modal>
                        )}
                        {showAddDefaultImputationsModal && (
                            <Modal
                                title={data.t.read("default_imputations")}
                                okAction={addDefaultImputations}
                                cancelAction={()=>setShowAddDefaultImputationsModal(false)}
                                okText={data.t.read("add")}
                                cancelText={data.t.read("cancel")}>
                                {data.t.read("default_imputation_confirmation")}
                            </Modal>
                        )}
                    </div>
                    :
                    <div className="d-flex loading-container w-100">
                        <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
                    </div>
                }
            </div>
        </div>
    )

}


export default inject('data')(observer(ExpensesImputations));