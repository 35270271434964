import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import iconv from 'iconv-lite';
import Modal from './modal';
import FormTextField from './form-textfield';
import FormSelectUser from './form-select-user';
import Utils from '../utils/utils';

import closeIcon2 from '../assets/img/icon-close2.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faPlusSquare, faSpinner, faBars, faTimes, faSync, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { downloadFile } from '../utils/file-manager';
import FormTextAreaAutoSaved from './form-text-area-auto-saved';

import CustomResizable from './custom-resizable';
import Checkbox from './checkbox';
import Select from 'react-select';
import { fetch } from '../utils/api-wrapper';

export default inject("data")(observer(class Invoice extends Component {

    constructor(props) {
        super(props);

        this.id = this.props.id;

        this.styleSelect = {
            control: (baseStyles, state) => ({
                ...baseStyles,
                border: "0 !important",
                boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
            })
        };

        this.state = {
            invoices: this.props.invoices,
            invoice: null,
            confirmDelete: false,
            saving: false,
            status: "",
            previousStatus: "",
            wasModified: false,
            confirmDeleteViewId: null,
            confirmDeleteItems: null,
            savingError: null,
            historyLogs: [],
            selectedLog: null,
            currentView: "data",
            pdfBuff: null,
            menuVisible: false,
            showConfirmRestartProcess: false,
            showRequestApproval: false,
            showDisapproval: false,
            showConfirmRestartPostProcess: false,
            billablesSelected: [],
            nonBillablesSelected: [],
            askSupplierCodesAvailable: null,
            promptNewSupplier: false,
            taxes: null,
            clearSupplierRecommendation: null,
            validating: false,
            PurchaseAndPayment: false,
            offices: []
        }

        this.taxcodes = ["T_IN", "TVH_IN", "N_IN", "TPS_IN"];

        if (this.props.data.client_name === "BCF" || this.props.data.client_name === "Xpens") {
            this.taxcodes.push("TVH-ON_IN");
        }

        if (this.props.data.config.engine === "demo") {
            this.taxcodes.push("BC_IN");
        }

        this.keyListener = evt => {
            if (evt.key === "Escape") {
                if (!this.state.wasModified && this.state.invoice != null) {
                    this.props.close(false);
                }
            }
        };

        this._view_id = 0;
    }

    /**
     * Compute the taxes from all the items listed in the invoice.
     */
    computeTaxes() {
        let lst = this.state.invoice.json.ListItem;
        const supplierTeranetIndex = this.state.invoice.json.ListSupplier.findIndex(supplier => supplier["Number"] === 'TERA0001');
        let taxes = [0, 0];
        let globalTaxCode = this.state.invoice.json.TaxCode ?? null;

        lst.forEach(item => {
            let taxCode = item.TaxCode ?? globalTaxCode;
            let montantBeforeRound;
            switch (taxCode) {
                case "T_IN":
                    taxes[0] += 0.05 * item.Total;
                    if (supplierTeranetIndex === -1) {
                        taxes[1] += 0.09975 * item.Total;
                    }
                    else {
                        montantBeforeRound = 0.09975 * item.Total; //this is for teranet only
                        taxes[1] += 1 * montantBeforeRound.toFixed(2);
                    }
                    break;
                case "TVH_IN":
                    taxes[0] += 0.13 * item.Total;
                    break;
                case "TPS_IN":
                    taxes[0] += 0.05 * item.Total;
                    break;
                case "GST":
                    taxes[0] += 0.05 * item.Total;
                    break;
                case "TVH-ON_IN":
                    taxes[0] += 0.13 * item.Total;
                    break;
                case "BC_IN":
                    taxes[0] += 0.05 * item.Total;
                    taxes[1] += 0.07 * item.Total;
                    break;

                default:
            }
        });

        for (let i = 0; i < taxes.length; i++) {
            taxes[i] = taxes[i].toFixed(2);
        }

        let total = parseFloat(this.state.invoice.json.Total);
        taxes.forEach(taxe => {
            total += parseFloat(taxe);
        });

        let invoice = this.state.invoice;
        invoice.json.TotalWithTax = total.toFixed(2);

        let counter = 1;
        let json = invoice.json;
        taxes.forEach(tax => {
            json["Tax" + counter++] = parseFloat(parseFloat(tax).toFixed(2));
        });

        this.setState({ taxes: taxes }, () => {
            this.updateInvoice(json, "json");
        });
    }

    computeGrandTotal() {
        let json = this.state.invoice.json;
        let taxes = [json.Tax1, json.Tax2];
        let grandTotal = json.Total;

        if (taxes) {
            taxes.forEach(tax => {
                grandTotal += tax;
            });
        }

        json.TotalWithTax = parseFloat(parseFloat(grandTotal).toFixed(2));

        this.updateInvoice(json, "json");
    }

    componentDidMount() {
        document.addEventListener("keyup", this.keyListener);

        this.fetchInvoice();
        this.fetchApprover();
        this.refreshOffices();
    }

    fetchApprover() {
        fetch("/client/users/Approver", "GET", {},
            response => {
                this.setState({
                    users: response.data,
                    approver: response.data ? response.data[0] : null
                })
            })
    }


    componentWillUnmount() {
        document.removeEventListener("keyup", this.keyListener);
    }

    fetchInvoice() {
        fetch("/invoices/partial/" + this.id, "GET", {},
            response => {
                let buf = null;

                if (response.data.pdf != null) {
                    buf = iconv.encode(response.data.pdf, 'iso-8859-1');
                    let bb = new Blob([buf], { type: "application/pdf" });
                    buf = window.URL.createObjectURL(bb);
                }

                if (response.data.invoice.json == null) {
                    response.data.invoice.json = {
                        ListItem: []
                    };
                }

                if (!response.data.invoice.json.ListSupplier) {
                    response.data.invoice.json.ListSupplier = [];
                }

                this.setState({
                    invoice: response.data.invoice,
                    previousStatus: response.data.invoice != null ? response.data.invoice.status : "",
                    pdf: response.data.pdf,
                    images: response.data.images,
                    pdfBuff: buf,
                });
                if (!this.state.invoice.json.Total) this.changeTotal();
                else if (!this.state.invoice.json.Tax1) this.computeTaxes();
                else {
                    this.setState({ taxes: [this.state.invoice.json.Tax1, this.state.invoice.json.Tax2] });
                }

                fetch("/suppliers/units", "GET", {}, response => {
                    let unit = response.data.find(e => e.unit === this.state.invoice.json.GLUnit);
                    if (unit) this.updateInvoice(unit.desc, "json", "GLUnit");
                });
            },
            response => {
                // invoice not found
            })
    }

    confirmDelete() {
        this.setState({
            confirmDelete: true
        });
    }

    deleteInvoice() {
        fetch("/invoices/" + this.id, "DELETE", {},
            response => {
                this.props.close(true)
            },
            response => {
                // invoice not found
            })
    }

    changeTaxes(value, taxNumber) {
        let taxes = this.state.taxes ?? [];

        taxes[Number(taxNumber[3]) - 1] = value;
        this.setState({ taxes: taxes });
    }

    requestApproval() {
        const invoice = this.state.invoice;
        invoice.json.approver = this.state.approver;
        invoice.json.approver.requested_date = new Date();
        invoice.json.approver.username = atob(this.state.approver.username);
        invoice.status = "Need_Approval";
        invoice.json.Status = "Need_Approval";
        if (invoice.status_details == null) {
            invoice.status_details = '';
        }
        invoice.status_details = Intl.DateTimeFormat(`${this.props.data.t.lang}-US`, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(new Date()) + ' : ' + this.props.data.firstName + ' ' + this.props.data.lastName + ' : ' + this.props.data.t.read("request_approval") + " " + this.props.data.t.read("sent") + " " + this.props.data.t.read("to") + " " + this.state.approver["last_name"] + " " + this.state.approver["first_name"] + '\n' + invoice.status_details;
        this.setState({ invoice: invoice });
        this.updateInvoice(null, null, null, null, null, () => this.props.close(true));
    }

    approveInvoice() {
        this.saving = true;
        const invoice = this.state.invoice;

        invoice.status = "Approved";
        invoice.json.status = "Approved";
        this.setState({ invoice: invoice });

        this.updateInvoice(null, null, null, null, null, () => this.props.close(true));
    }

    disApproveInvoice() {
        const invoice = this.state.invoice;
        this.setState({ saving: true });
        invoice.status = "Denied";
        invoice.json.Status = "Denied";
        if (invoice.status_details == null) {
            invoice.status_details = '';
        }
        invoice.status_details = Intl.DateTimeFormat(`${this.props.data.t.lang}-US`, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(new Date()) + ' : ' + this.props.data.firstName + ' ' + this.props.data.lastName + ' : ' + this.state.causeDenial + '\n' + invoice.status_details;

        this.setState({ invoice: invoice });

        this.updateInvoice(null, null, null, null, null, () => this.props.close(true));
    }


    updateInvoice(value, field, subField, listNo = null, subSubField = null, doneCallback = null) {

        let invoice = this.state.invoice;
        let savingError = null;
        let fieldUpdateStatus = 4;

        if (["Tax1", "Tax2"].includes(subField)) {
            this.changeTaxes(value, subField);
        }

        try {
            if (subField != null) {
                if (listNo === null) {
                    invoice[field][subField] = value;
                    invoice[field][subField + "Status"] = fieldUpdateStatus;
                }
                else {
                    if (subField === "ListItem") {
                        let tmpNo = -1;

                        invoice.json.ListItem.forEach((item, i) => {
                            if (item._view_id === listNo) {
                                tmpNo = i;
                            }
                        })

                        listNo = tmpNo;
                    }

                    if (subSubField === null) {
                        invoice[field][subField][listNo] = value;
                    } else {
                        invoice[field][subField][listNo][subSubField] = value;
                        invoice[field][subField][listNo][subSubField + "Status"] = fieldUpdateStatus;
                    }
                }

            }
            else if (field != null) {
                invoice[field] = value;

                if (invoice[field + "Status"] != null) {
                    invoice[field + "Status"] = fieldUpdateStatus;
                }
            }
        }
        catch (e) {
            console.log(e)
            savingError = this.props.data.t.read("cannot_save_if_not_first_processed");

            this.setState({
                savingError: savingError
            });
        }

        if (savingError === null) {
            this.setState({
                saving: true,
                wasModified: true,
                savingError: savingError,
            }, () => {
                let formData = new FormData();
                formData.append("status", invoice.status);
                formData.append("status_details", invoice.status_details == null ? "" : invoice.status_details);

                if (invoice.json != null) {
                    let json = invoice.json;

                    if (json.ListItem != null) {
                        json = JSON.parse(JSON.stringify(json));
                    }

                    formData.append("json", JSON.stringify(json));
                }

                fetch("/invoices/" + this.id, "PUT", formData,
                    response => {
                        if (invoice.status === "Pending") {
                            this.props.close(true);

                            if (doneCallback != null) {
                                doneCallback();
                            }
                        }
                        else if (invoice.status === "Completed") {
                            this.setState({
                                invoice: invoice,
                                saving: false,
                                confirmDeleteViewId: null,
                                confirmDeleteItems: null,
                            }, () => {
                                if (this.state.previousStatus !== invoice.status) {
                                    this.fetchInvoice();
                                }

                                if (doneCallback != null) {
                                    doneCallback();
                                }
                            });
                        }
                        else {
                            this.setState({
                                previousStatus: invoice.status,
                                invoice: invoice,
                                saving: false,
                                showRequestApproval: false,
                                showDisapproval: false,
                                confirmDeleteViewId: null,
                                confirmDeleteItems: null,
                            }, () => {
                                if (doneCallback != null) {
                                    doneCallback();
                                }
                            })
                        }
                    },
                    response => {
                        this.setState({
                            savingError: this.props.data.t.read("internal_saving_error")
                        })
                    })
            });
        }
    }

    formatAmount(number) {
        let result = this.props.data.t.read("n_a");

        if (number != null && number > 0) {
            result = "$ " + number.toFixed(2);
        }

        return result;
    }

    formatData(data) {
        if (data == null || data.length === 0) {
            data = this.props.data.t.read("n_a");
        }

        return data;
    }

    deleteItem() {
        let invoice = this.state.invoice;

        for (let i = 0; i < invoice.json.ListItem.length; i++) {
            if (invoice.json.ListItem[i]._view_id === this.state.confirmDeleteViewId) {
                invoice.json.ListItem.splice(i, 1);
                invoice.json.ListItem = invoice.json.ListItem.map((item, index) => ({
                    ...item, // Spread the existing properties
                    "_view_id": index,
                }));
                break;
            }
        }

        this.changeTotal();
        this.computeTaxes();
        this.updateInvoice();
        this.updateViewIds();
    }

    updateViewIds() {
        let invoice = this.state.invoice.json;
        for (let i = 0; i < invoice.ListItem.length; i++) {
            invoice.ListItem[i]._view_id = i;
        }
        this.updateInvoice(invoice, "json");
    }

    confirmRemoveLine(vewId) {
        this.setState({
            confirmDeleteViewId: vewId,
        })
    }

    addItem(billable = false) {
        let invoice = this.state.invoice;

        if(!invoice.json.ListItem){
            invoice.json.ListItem = []
        }
        invoice.json.ListItem.push({
            "IsBillable": billable ? 1 : 0,
            "Description": "",
            "GLOffice": invoice.json?.GLOffice,
            "GLNatural": invoice.json?.GLNatural,
            "CostType": invoice.json?.CostType,
            "Total": 0
        });

        this.updateInvoice();
    }


    changeView(view) {
        this.setState({
            currentView: view,
            historyLogs: [],
            selectedLog: null,
        }, () => {
            if (view === "history") {
                fetch("/invoices/" + this.id + "/history", "GET", {},
                    response => {
                        this.setState({
                            historyLogs: response.data.history,
                        })
                    })
            }
        })
    }

    toggleSelectAll(checkbox, sectionName) {
        setTimeout(() => {
            if (sectionName === "non-billable") {
                this.setState({
                    nonBillablesSelected: this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => !this.isItemBillable(item)).length !== this.state.nonBillablesSelected.length ? this.state.invoice.json.ListItem.filter(item => !this.isItemBillable(item)) : []
                })
            }
            else {
                this.setState({
                    billablesSelected: this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => this.isItemBillable(item)).length !== this.state.billablesSelected.length ? this.state.invoice.json.ListItem.filter(item => this.isItemBillable(item)) : []
                })
            }
        }, 1)

    }

    toggleItemSelected(section, item) {
        let list = section === "billable" ? this.state.billablesSelected : this.state.nonBillablesSelected;
        let idx = list.indexOf(item);

        if (idx !== -1) {
            list.splice(idx, 1)
        }
        else {
            list.push(item);
        }

        if (section === "billable") {
            this.setState({
                billablesSelected: list
            })
        }
        else {
            this.setState({
                nonBillablesSelected: list
            })
        }
    }

    changeTotal() {
        let invoice = this.state.invoice;
        let total = 0;

        invoice.json.ListItem.forEach(item => {
            total += item.Total;
        });
        invoice.json.Total = total;
        this.setState({ invoice: invoice });
    }

    validateInvoice() {
        this.setState({ validating: true });
        let formData = new FormData();
        formData.append("json", JSON.stringify(this.state.invoice.json));
        formData.append("taxes", JSON.stringify(this.state.taxes));

        let div = document.getElementById("errorMessages");
        div.innerHTML = "";

        fetch(`/invoices/${this.id}/validate`, "PUT", formData,
            response => {
                this.setState({ validating: false });
                if (response.data.errors.length > 0) {
                    // Add error message
                    let content = "Errors : <ul>";
                    response.data.errors.slice(0, 3).forEach(error => {
                        content += `<li>${error}</li>`;
                    });
                    content += "</ul>";
                    div.innerHTML = content;
                }
                else {
                    let invoice = this.state.invoice;
                    invoice.status = "Validated";
                    this.setState({ invoice: invoice });
                    this.updateInvoice();

                    this.getNextInvoice(true, true);
                    //setTimeout(() => this.props.close(true), 500);
                }
            }
        );
    }
    /**
     * when getting the next invoice we call ask to remove it from list 
     * @param {boolean} remove 
     * @param {boolean} forward 
     */
    getNextInvoice(remove = true, forward = true) {
        this.setState({ invoice: null });
        const invoices = this.state.invoices;
        let nextIndex;
        const indexCurrentInvoice = invoices.findIndex(
            invoice => invoice._id === this.id
        );


        if (forward === true) {
            if (indexCurrentInvoice + 1 < invoices.length) {
                nextIndex = indexCurrentInvoice + 1
            }
            else {
                nextIndex = 0
            }
        }

        else if (forward === false) {
            if (indexCurrentInvoice - 1 >= 0)
                nextIndex = indexCurrentInvoice - 1;
            else
                nextIndex = invoices.length - 1;
        }

        if (remove === true) {
            invoices.splice(indexCurrentInvoice, 1);
            nextIndex = indexCurrentInvoice;
        }

        this.setState({ invoices: invoices });
        if (invoices.length > nextIndex) {
            this.id = invoices[nextIndex]._id;
            this.fetchInvoice();
        }
        else
            this.props.close(true);
    }

    confirmDeleteAction(section) {
        let list = section === "billable" ? this.state.billablesSelected : this.state.nonBillablesSelected;

        if (section === "billable") {
            this.setState({
                confirmDeleteItems: list,
                billablesSelected: []
            })
        }
        else {
            this.setState({
                confirmDeleteItems: list,
                nonBillablesSelected: []
            })
        }
    }

    deleteItems() {
        let invoice = this.state.invoice;

        this.state.confirmDeleteItems.forEach(item => {
            invoice.json.ListItem.splice(invoice.json.ListItem.indexOf(item), 1);
        })

        this.changeTotal();
        this.computeTaxes();
        this.updateInvoice();
        this.updateViewIds();
    }

    showStatus(value) {
        return (value != null && value !== "") || this.props.data.config.frontend_invoice == null || !this.props.data.config.frontend_invoice.hide_na_fields ? "flex" : "none";
    }

    isItemBillable(item) {
        return item.IsBillable == null ? this.state.invoice.json.IsBillable : (item.IsBillable === true || item.IsBillable === 1);
    }

    hasBillableItems() {
        return this.state.invoice.json && this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => this.isItemBillable(item)).length > 0;
    }

    hasNonBillableItems() {
        return this.state.invoice.json && this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => !this.isItemBillable(item)).length > 0;
    }

    restartProcess() {
        this.updateInvoice("Pending", "status", null);
    }

    restartPostProcess() {
        let formData = new FormData();

        fetch("/invoices/" + this.id + "/restart-rules", "POST", formData,
            response => {
                this.setState({ showConfirmRestartPostProcess: false }, () => {
                    this.fetchInvoice();
                });
            }
        );
    }

    supplierChosen(supplier, baseSupplierName = null) {
        if (supplier != null) {

            if (this.state.invoice.json.ListSupplier.length === 0)
                this.updateInvoice({ "Number": "", "Name": "", "Index": "" }, "json", "ListSupplier", 0)


            this.updateInvoice(supplier.name, "json", "ListSupplier", 0, "Name", () => {

                this.updateInvoice(supplier._id, "json", "ListSupplier", 0, "Index");
                this.updateInvoice(supplier.number, "json", "ListSupplier", 0, "Number");
                fetch("/suppliers/code/" + supplier._id, "GET", null,
                    response => {
                        this.state.clearSupplierRecommendation();

                        if (response.data.code_found != null) {
                            this.updateInvoice(response.data.code_found, "json", "ListSupplier", 0, "Number");

                            const invoice_json = this.state.invoice.json
                            const items = invoice_json.ListItem.map(it => ({
                                ...it,
                                GLNatural: supplier.gl_code,
                                CostType: supplier.cost_type,
                                IsBillable: supplier.IsBillable
                            }));


                            invoice_json.ListItem = items
                            invoice_json.GLNatural = supplier.gl_code
                            invoice_json.CostType = supplier.cost_type
                            this.updateInvoice(invoice_json, "json");
                        }
                        else if (response.data.codes_available.length > 0) {
                            this.setState({
                                currentSupplier: supplier,
                                chosenSupplierNumber: null,
                                askSupplierCodesAvailable: response.data.codes_available,
                            })
                        }
                    }
                );
            });
        }
        else {
            this.setState({
                promptNewSupplier: true,
                newSupplierName: baseSupplierName,
                newSupplierAltName: "",
                newSupplierZipcode: "",
                newSupplierCountry: null,
                newSupplierCity: null,
                newSupplierState: null,
                newSupplierAddress: null,
                newSupplierOffice: null,
                newSupplierGL: null,
                newSupplierCostType: null,
                newSupplierNumber: "",
                newSupplierBillable: "true",
                newSupplierTaxCode: "T_IN",
                newSupplierActive: true
            })
        }
    }

    unitChosen(unit, baseUnitName = null) {
        if (unit != null) {
            this.updateInvoice(unit.name, "json", "GLUnit");
        }
    }
    refreshOffices() {
        const sorting = [{ "id": "name", "desc": false }]
        const filters = [{ 'id': "id_client", 'value': this.props.data.id_client }];
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(0)}`;
        fetch(`/offices/${searchData}`, "GET", {},
            response => {
                this.setState({
                    offices: response.data.offices
                });
            });
    }

    confirmSupplierNumber() {
        let valid = true;

        if (this.state.chosenSupplierNumber != null) {
            let formData = new FormData();

            formData.append("id_supplier", this.state.currentSupplier._id)
            formData.append("code", this.state.chosenSupplierNumber);

            fetch("/supplier-codes", "post", formData,
                response => {
                    this.updateInvoice(this.state.chosenSupplierNumber, "json", "ListSupplier", 0, "Number", () => {

                        this.setState({
                            chosenSupplierNumber: null,
                            askSupplierCodesAvailable: null
                        })
                    });
                }
            );
        }
        else {
            valid = false;
        }

        return valid;
    }

    confirmNewSupplier() {
        let valid = true;
        if (this.state.newSupplierName.trim().length > 2 &&
            this.state.newSupplierCountry?.trim().length > 2 &&
            this.state.newSupplierZipcode?.replace(" ", "").length > 2 &&
            this.state.newSupplierAddress?.trim().length > 2 &&
            (this.props.data.config?.engine === 'juris' || this.state.newSupplierNumber.trim().length > 2) &&
            this.state.newSupplierGL?.trim().length > 1 &&
            (this.props.data.config?.engine !== 'juris' || (this.state.newSupplierCity?.replace(" ", "").length > 1 && this.state.newSupplierState?.replace(" ", "").length > 1))
        ) {
            let names = [this.state.newSupplierName];

            if (this.state.newSupplierAltName.trim().length > 0) {
                names.push(this.state.newSupplierAltName);
            }

            let formData = new FormData();

            formData.append("names", JSON.stringify(names));
            formData.append("zipcode", this.state.newSupplierZipcode);
            formData.append("country", this.state.newSupplierCountry);
            formData.append("state", this.state.newSupplierState);
            formData.append("city", this.state.newSupplierCity);
            formData.append("address", this.state.newSupplierAddress);
            formData.append("gl_code", this.state.newSupplierGL);
            formData.append("cost_type", this.state.newSupplierCostType);
            formData.append("active", this.state.newSupplierActive);
            formData.append("supplier_number", this.state.newSupplierNumber);
            formData.append("tax_code", this.state.newSupplierTaxCode);
            formData.append("is_billable", this.state.newSupplierBillable);
            formData.append('office', JSON.stringify(this.state.newSupplierOffice))

            fetch("/suppliers/", "post", formData,
                response => {
                    let supplier = {}
                    supplier.name = this.state.newSupplierName;
                    supplier._id = response.data.id;
                    supplier.country = this.state.newSupplierCountry;
                    supplier.zipcode = this.state.newSupplierZipcode;
                    supplier.state = this.state.newSupplierState;
                    supplier.office = this.state.newSupplierOffice;
                    supplier.city = this.state.newSupplierCity;
                    supplier.number = this.state.newSupplierNumber;
                    supplier.IsBillable = this.state.newSupplierBillable === "true";
                    supplier.tax_code = this.state.newSupplierTaxCode;
                    supplier.gl_code = this.state.newSupplierGL;
                    supplier.cost_type = this.state.newSupplierCostType;

                    this.setState({
                        promptNewSupplier: false,
                    }, () => {
                        this.supplierChosen(supplier, null);
                    })
                }
            );
        }
        else {
            valid = false;
        }

        return valid;
    }

    changeTaxCode(e, lineId) {
        let taxCode = e.target.innerText;
        let indexNextCode = this.taxcodes.findIndex(el => el === taxCode) + 1;
        indexNextCode = (indexNextCode >= this.taxcodes.length ? 0 : indexNextCode);

        let newTaxCode = this.taxcodes[indexNextCode];
        this.updateInvoice(newTaxCode, "json", "ListItem", lineId, "TaxCode");
        this.changeTotal();
        this.computeTaxes();
    }

    render() {
        let supplierName = "";
        let supplierNumber = "";
        let billingDate = "";
        let dueDate = "";
        let invoiceNo = "";
        let invoiceRef = "";
        let currency = "";
        let total = "";
        let totalWithTax = "";
        let glUnit = "";
        let tax1 = "";
        let tax2 = "";
        let approver = null;

        if (this.state.taxes != null) {
            tax1 = this.state.taxes[0];
            tax2 = this.state.taxes[1] ?? "";
        }

        if (this.state.invoice != null) {
            supplierName = this.state.invoice.json && this.state.invoice.json.ListSupplier && this.state.invoice.json.ListSupplier.length > 0 ? this.state.invoice.json.ListSupplier[0].Name : "";
            supplierNumber = this.state.invoice.json && this.state.invoice.json.ListSupplier && this.state.invoice.json.ListSupplier.length > 0 ? this.state.invoice.json.ListSupplier[0].Number : "";
            billingDate = this.state.invoice.json ? this.state.invoice.json.InvoiceDate : "";
            dueDate = this.state.invoice.json ? this.state.invoice.json.DueDate : "";
            invoiceNo = this.state.invoice.json ? this.state.invoice.json.InvoiceNo : "";
            invoiceRef = this.state.invoice.json ? this.state.invoice.json.InvoiceRef : "";
            currency = this.state.invoice.json ? this.state.invoice.json.Currency : "";
            total = this.state.invoice.json && this.state.invoice.json.Total ? this.state.invoice.json.Total.toFixed(2) : "";
            totalWithTax = this.state.invoice.json ? this.state.invoice.json.TotalWithTax : "";
            glUnit = this.state.invoice.json ? this.state.invoice.json.GLUnit : "";
            tax1 = this.state.invoice.json && this.state.invoice.json.Tax1 && this.state.invoice.json.Tax1 !== "" ? this.state.invoice.json.Tax1 : tax1;
            tax2 = this.state.invoice.json && this.state.invoice.json.Tax2 && this.state.invoice.json.Tax2 !== "" ? this.state.invoice.json.Tax2 : tax2;
            approver = this.state.invoice.json ? this.state.invoice.json.approver ? this.state.invoice.json.approver : null : null;
        }

        if (tax1 === 0) tax1 = "";
        if (tax2 === 0) tax2 = "";

        return this.state.invoice != null ?
            <div className="invoice bg-white">
                {
                    this.state.savingError != null ?
                        <Modal title={this.props.data.t.read("error")} okAction={() => this.setState({ savingError: null })} okText={"ok"}>
                            <div className="text-danger">
                                {this.state.savingError}
                            </div>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.showConfirmRestartProcess ?
                        <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ showConfirmRestartProcess: false })} okAction={() => this.restartProcess()} okText={this.props.data.t.read("yes")} cancelText={this.props.data.t.read("cancel")}>
                            {this.props.data.t.read("invoice.restart_processing")} ?
                        </Modal>
                        :
                        null
                }
                {
                    this.state.showRequestApproval ?
                        <Modal overflow="visible" invalid={this.state.approver === null} title={this.props.data.t.read("request_approval")} cancelAction={() => this.setState({ showRequestApproval: false })} okAction={() => this.requestApproval()} okText={this.props.data.t.read("submit")} cancelText={this.props.data.t.read("cancel")}>
                            <FormSelectUser chosenOption={this.state.invoice.json.approver} options={this.state.users.sort((a, b) => a["first_name"] + a["last_name"] > b["first_name"] + b["last_name"] ? 1 : -1)} optionChanged={option => { this.setState({ approver: option }) }} />
                        </Modal>
                        :
                        null
                }
                {
                    this.state.showDisapproval ?
                        <Modal overflow="visible" title={this.props.data.t.read("denial_cause_placeholder")} cancelAction={() => this.setState({ showDisapproval: false })} okAction={() => this.disApproveInvoice()} okText={this.props.data.t.read("submit")} cancelText={this.props.data.t.read("cancel")}>
                            <input className='form-control' type="text" onChange={e => this.setState({ causeDenial: e.target.value })} />
                        </Modal>
                        :
                        null
                }
                {
                    this.state.showConfirmRestartPostProcess ?
                        <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ showConfirmRestartPostProcess: false })} okAction={() => this.restartPostProcess()} okText={this.props.data.t.read("yes")} cancelText={this.props.data.t.read("cancel")}>
                            {this.props.data.t.read("invoice.restart_post_processing")} ?
                        </Modal>
                        :
                        null
                }
                {
                    this.state.confirmDeleteViewId != null ?
                        <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ confirmDeleteViewId: null })} okAction={() => this.deleteItem()} okText={this.props.data.t.read("delete")} cancelText={this.props.data.t.read("cancel")}>
                            {this.props.data.t.read("delete")} ?
                        </Modal>
                        :
                        null
                }
                {
                    this.state.confirmDeleteItems != null ?
                        <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ confirmDeleteItems: null })} okAction={() => this.deleteItems()} okText={this.props.data.t.read("delete")} cancelText={this.props.data.t.read("cancel")}>
                            {this.props.data.t.read("delete")} ?
                        </Modal>
                        :
                        null
                }
                {
                    this.state.confirmDelete ?
                        <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ confirmDelete: false })} okAction={() => this.deleteInvoice()} okText={this.props.data.t.read("delete")} cancelText={this.props.data.t.read("cancel")}>
                            {this.props.data.t.read("delete")} ?
                        </Modal>
                        :
                        null
                }
                {
                    this.state.askSupplierCodesAvailable != null ?
                        <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ askSupplierCodesAvailable: null })} okAction={() => this.confirmSupplierNumber()} okText={this.props.data.t.read("save")} cancelText={this.props.data.t.read("cancel")}>
                            <p>
                                {this.props.data.t.read("invoice.choose_supplier_number_for") + " "}
                                <strong>{this.state.currentSupplier.name}</strong>
                            </p>
                            <div>
                                {
                                    this.state.askSupplierCodesAvailable.map((sup, idx) => {
                                        return <div key={sup.code}>
                                            <label onClick={e => { this.setState({ chosenSupplierNumber: sup.code }); }}>
                                                <input type="radio" name="supplier-number-radio" checked={this.state.chosenSupplierNumber === sup.code} value={sup.code} onChange={() => { }} />
                                                <strong> #{sup.code}</strong>  : {sup.desc}
                                            </label>
                                        </div>
                                    })
                                }
                            </div>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.promptNewSupplier ?
                        <Modal title={this.props.data.t.read("new_supplier")} cancelAction={() => this.setState({ promptNewSupplier: false })} okAction={() => this.confirmNewSupplier()} okText={this.props.data.t.read("save")} cancelText={this.props.data.t.read("cancel")}>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("invoice.supplier_name")} className='form-control' type="text" value={this.state.newSupplierName} onChange={e => this.setState({ newSupplierName: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("alt_name") + " (" + this.props.data.t.read("optional") + ")"} className='form-control' type="text" value={this.state.newSupplierAltName} onChange={e => this.setState({ newSupplierAltName: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input readOnly={this.props.data.config.engine === "juris"} placeholder={this.props.data.t.read("supplier_code")} className='form-control' type="text" value={this.state.newSupplierNumber} onChange={e => this.setState({ newSupplierNumber: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("supplier_gl")} className='form-control' type="text" value={this.state.newSupplierGL} onChange={e => this.setState({ newSupplierGL: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("invoice.cost_type")} className='form-control' type="text" value={this.state.newSupplierCostType} onChange={e => this.setState({ newSupplierCostType: e.target.value })} />
                            </div>
                            {this.props.data.config.engine === "juris" &&
                                <div className='mt-1'>
                                    <Select
                                        name="offices"
                                        value={this.state.newSupplierOffice ?? null}
                                        options={
                                            this.state.offices
                                                ? this.state.offices.map(o => ({ label: o.name, value: o._id.$oid }))
                                                : []
                                        }
                                        styles={this.styleSelect}
                                        classNamePrefix="select"
                                        onChange={(choice) => this.setState({ newSupplierOffice: choice })}
                                        placeholder={this.props.data.t.read("invoice.office")}
                                    />
                                </div>
                            }
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("supplier_address")} className='form-control' type="text" value={this.state.newSupplierAddress} onChange={e => this.setState({ newSupplierAddress: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("country")} className='form-control' type="text" value={this.state.newSupplierCountry} onChange={e => this.setState({ newSupplierCountry: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("state")} className='form-control' type="text" value={this.state.newSupplierState} onChange={e => this.setState({ newSupplierState: e.target.value })} />
                            </div>
                            <div className='mb-2'>
                                <input placeholder={this.props.data.t.read("city")} className='form-control' type="text" value={this.state.newSupplierCity} onChange={e => this.setState({ newSupplierCity: e.target.value })} />
                            </div>
                            <div className='mb-4'>
                                <input placeholder={this.props.data.t.read("zipcode") + " ex: X1X 1X1"} className='form-control' type="text" value={this.state.newSupplierZipcode} onChange={e => this.setState({ newSupplierZipcode: e.target.value })} />
                            </div>
                            {
                                this.props.data.config.type === "legal" ?
                                    <>
                                        <div className='mb-4'>
                                            <h5>{this.props.data.t.read("supplier.tax_code")}</h5>
                                            <select value={this.state.newSupplierTaxCode} placeholder={this.props.data.t.read("supplier.tax_code")} className='form-control' onChange={e => this.setState({ newSupplierTaxCode: e.target.value })}>
                                                <option value="N_IN">{this.props.data.t.read("supplier.choices.tax_code.n_in")}</option>
                                                <option value="T_IN">{this.props.data.t.read("supplier.choices.tax_code.t_in")}</option>
                                            </select>
                                        </div>
                                        <div className='mb-2'>
                                            <h5>{this.props.data.t.read("supplier.is_billable")}?</h5>
                                            <select value={this.state.newSupplierBillable} placeholder={this.props.data.t.read("supplier.is_billable")} className='form-control' onChange={e => this.setState({ newSupplierBillable: e.target.value })} >
                                                <option value="true">{this.props.data.t.read("supplier.choices.is_billable.true")}</option>
                                                <option value="false">{this.props.data.t.read("supplier.choices.is_billable.false")}</option>
                                            </select>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </Modal>
                        :
                        null
                }
                {
                    this.state.pdfBuff !== null &&
                    <CustomResizable type="Invoice">
                        <div className="image-section position-relative">
                            <div className="d-flex main-tabs">
                                <div>
                                    <div className="tab-title p-3 pointer " onClick={() => this.setState({ menuVisible: true })}>
                                        <FontAwesomeIcon icon={faBars} style={{ fontSize: "20px" }} />
                                    </div>
                                </div>
                                {
                                    this.state.menuVisible ?
                                        <div className="left-menu-section">
                                            <div>
                                                <div className="d-flex main-tabs mb-4">
                                                    <div className="tab-title text-secondary p-3 pointer" onClick={() => this.setState({ menuVisible: false })}>
                                                        <FontAwesomeIcon className="text-secondary" icon={faTimes} style={{ fontSize: "25px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li>
                                                            <span className="mock-link" onClick={() => downloadFile(this.id, "elite_xml")}>
                                                                {this.props.data.t.read("download_xml")}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="mock-link" onClick={() => downloadFile(this.id, "json")}>
                                                                {this.props.data.t.read("download_json")}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="mock-link" onClick={() => downloadFile(this.id, "pdf")}>
                                                                {this.props.data.t.read("download_pdf")}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <div className="mock-link text-danger" onClick={() => this.confirmDelete()}>
                                                                {this.props.data.t.read("delete")}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <object width="100%" height="100%" type="application/pdf" data={this.state.pdfBuff} aria-label="PDF"></object>
                        </div>
                    </CustomResizable>
                }
                <div className="content-section d-flex flex-column">
                    <div className="d-flex main-tabs">
                        {
                            this.state.invoices && this.state.invoices.length > 1 &&
                            <div className='pointer icon-box'>
                                <FontAwesomeIcon icon={faAngleLeft} className="fa-2x pointer" color='#F39800' onClick={() => this.getNextInvoice(false, false)} />
                            </div>
                        }
                        <div className={"flex-grow-1 p-3 tab-title " + (this.state.currentView === "data" ? "tab-selected" : "")} onClick={() => this.changeView("data")}>
                            {this.props.data.t.read("data")}
                        </div>
                        <div className={"flex-grow-1 p-3 tab-title " + (this.state.currentView === "history" ? "tab-selected" : "")} onClick={() => this.changeView("history")}>
                            {this.props.data.t.read("history")}
                        </div>
                        <div className="p-3 pointer" onClick={() => this.props.close(this.props.close(this.state.wasModified))}>
                            <img src={closeIcon2} alt="Close" />
                        </div>
                        {
                            this.state.invoices && this.state.invoices.length > 1 &&
                            <div className='pointer icon-box'>
                                <FontAwesomeIcon icon={faAngleRight} className="fa-2x pointer" color='#F39800' onClick={() => this.getNextInvoice(false, true)} />
                            </div>
                        }
                    </div>
                    {
                        this.state.currentView === "data" ?
                            <div className="p-4 d-flex flex-column flex-grow-1">
                                <div className="row">
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.status_txt")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <select className="form-control form-control-sm input-group-sm" disabled={this.state.saving} value={this.state.invoice.status} onChange={(e) => this.updateInvoice(e.target.value, "status", null)}>
                                            {
                                                this.props.data.config.invoice_status.map(tmp => {
                                                    return <option key={tmp} value={tmp}>{this.props.data.t.read("status." + tmp.toLowerCase())}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.supplier_name")} :
                                    </div>

                                    <div className="col-md-5 invoice-value">
                                        <FormTextField passClearRecommendationFunc={(fnc) => this.setState({ clearSupplierRecommendation: fnc })} format="data" locked={this.state.saving} value={supplierName} validateChange={val => this.updateInvoice(val, "json", "ListSupplier", 0, "Name")} searchURL="/suppliers/search/" searchCallback={(sup, typedText) => this.supplierChosen(sup, typedText)} />
                                    </div>
                                    {this.props.data.config?.engine === 'juris' &&
                                        <>
                                            <div className='m-1'>
                                                {this.props.data.t.read("achat_and_payment")} :
                                            </div>
                                            <Checkbox isChecked={this.state.invoice.json?.PurchaseAndPayment}
                                                onChange={() => {
                                                    this.updateInvoice(!this.state.invoice.json?.PurchaseAndPayment, 'json', 'PurchaseAndPayment');
                                                }
                                                }
                                                disabled={this.state.saving} />
                                        </>
                                    }
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.supplier_number")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="data" locked={this.state.saving} value={supplierNumber}
                                            validateChange={val => {
                                                this.updateInvoice(val, "json", "ListSupplier", 0, "Number");
                                            }
                                            }
                                        />
                                    </div>
                                    {this.state.invoice?.json?.PurchaseAndPayment && this.props.data.config?.payment_modes &&
                                        <>
                                            <div className="col-md-2">
                                                {this.props.data.t.read("payment_mode")} :
                                            </div>
                                            <div className='col-md-4'>
                                                <Select
                                                    isDisabled={this.state.saving}
                                                    name="payment_mode"
                                                    value={this.state.invoice.json?.PaymentMode || null}
                                                    options={
                                                        this.props.data.config.payment_modes
                                                            ? this.props.data.config.payment_modes.map(p => ({ value: p.id, label: p.name }))
                                                            : []
                                                    }
                                                    styles={this.styleSelect}
                                                    classNamePrefix="select"
                                                    onChange={(choice) => this.updateInvoice(choice, 'json', 'PaymentMode')}
                                                />

                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="row" style={{ display: this.showStatus(billingDate) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.billing_date")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="date" locked={this.state.saving} value={billingDate} validateChange={val => this.updateInvoice(val, "json", "InvoiceDate")} />
                                    </div>
                                    {this.state.invoice?.json?.PurchaseAndPayment && this.props.data.config?.banks &&
                                        <>
                                            <div className="col-md-2">
                                                {this.props.data.t.read("bank")} :
                                            </div>
                                            <div className='col-md-4'>
                                                <Select
                                                    name="payment_bank"
                                                    isDisabled={this.state.saving}
                                                    value={this.state.invoice?.json?.PaymentBank || null}
                                                    options={
                                                        this.props.data.config.banks
                                                            ? this.props.data.config.banks.map(p => ({ value: p.key, label: p.value }))
                                                            : []
                                                    }
                                                    styles={this.styleSelect}
                                                    classNamePrefix="select"
                                                    onChange={(choice) => this.updateInvoice(choice, 'json', 'PaymentBank')}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>

                                {
                                    !this.props.data.config.frontend_invoice.hide_due_date ?
                                        <div className="row" style={{ display: this.showStatus(dueDate) }}>
                                            <div className="col-md-3">
                                                {this.props.data.t.read("invoice.due_date")} :
                                            </div>
                                            <div className="col-md-3 invoice-value">
                                                <FormTextField format="date" locked={this.state.saving} value={dueDate} validateChange={val => this.updateInvoice(val, "json", "DueDate")} />
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                <div className="row" style={{ display: this.showStatus(invoiceNo) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.invoice_number")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="data" locked={this.state.saving} value={invoiceNo} validateChange={val => this.updateInvoice(val, "json", "InvoiceNo")} />
                                    </div>
                                </div>
                                <div className="row" style={{ display: this.showStatus(invoiceRef) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.invoice_ref")} :
                                    </div>
                                    <div className="col-md-6 invoice-value">
                                        <FormTextField format="data" locked={this.state.saving} value={invoiceRef} validateChange={val => this.updateInvoice(val, "json", "InvoiceRef")} />
                                    </div>
                                </div>

                                <div className="row" style={{ display: this.showStatus(currency) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.currency")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="data" locked={this.state.saving} value={currency} validateChange={val => this.updateInvoice(val, "json", "Currency")} />
                                    </div>
                                </div>

                                <div className="row" style={{ display: this.showStatus(total) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.total")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="money" locked={this.state.saving} value={total} validateChange={val => { this.updateInvoice(val, "json", "Total"); this.computeGrandTotal(); }} />
                                    </div>
                                </div>

                                <div className="row" style={{ display: this.showStatus(totalWithTax) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.total_with_tax")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="money" locked={this.state.saving} value={totalWithTax} validateChange={val => this.updateInvoice(val, "json", "TotalWithTax")} />
                                    </div>
                                </div>

                                <div className="row" style={{ display: this.showStatus(glUnit) }}>
                                    <div className="col-md-3">
                                        {this.props.data.t.read("invoice.unit")} :
                                    </div>
                                    <div className="col-md-3 invoice-value">
                                        <FormTextField format="data" locked={this.state.saving} value={glUnit} validateChange={val => this.updateInvoice(val, "json", "GLUnit")} searchURL="/suppliers/unit/search/" hideAddOperation={true} hideActionButtons={true} hideNoResults={true} />
                                    </div>
                                </div>
                                {
                                    Utils.userHasPageAccess(this.props.data, "approval") && approver !== null &&
                                    <div className="row" >
                                        <div className="col-md-3">
                                            {this.props.data.t.read("invoice.approver")} :
                                        </div>
                                        <div className="col-md-3 invoice-value">
                                            <FormTextField format="data" locked={true} value={approver.first_name + " " + approver.last_name} />
                                        </div>
                                    </div>
                                }

                                {
                                    this.props.data.config.frontend_invoice == null || !this.props.data.config.frontend_invoice.hide_cost_details_table ?
                                        <div className="mt-5 mr-3">
                                            <h4 className="hidden-actions-container d-flex justify-content-between">
                                                <span>
                                                    {this.props.data.t.read("invoice.cost_details")}
                                                    <span className="hidden-actions">
                                                        <button className="btn btn-sm btn-link" onClick={() => this.addItem(true)}>
                                                            <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                                        </button>
                                                    </span>
                                                </span>
                                                <div className="text-right">
                                                    <button className={"btn btn-secondary btn-sm btn-xs " + (this.state.billablesSelected.length > 0 ? "" : "faded")} onClick={() => this.confirmDeleteAction("billable")} >{this.props.data.t.read("delete")}</button>
                                                </div>
                                            </h4>
                                            <table className="table invoice-items-table mt-3 font-xsmall">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "3%" }}>
                                                            <input type="checkbox" id="all-billable-checkbox" checked={this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => this.isItemBillable(item)).length === this.state.billablesSelected.length && this.state.billablesSelected.length > 0} onChange={() => { }} onClick={e => this.toggleSelectAll(e.target, "billable")} />
                                                        </th>
                                                        <th style={{ width: "30%" }}>{this.props.data.t.read("invoice.description")}</th>
                                                        <th hidden={this.props.data.config.frontend_invoice.hide_office_in_gl_details_table} style={{ width: "10%" }}>{this.props.data.t.read("invoice.office")}</th>
                                                        <th hidden={this.props.data.config.frontend_invoice.hide_work_date} style={{ width: "13%" }}>{this.props.data.t.read("invoice.work_date")}</th>
                                                        <th style={{ width: "15%" }}>{this.props.data.t.read("invoice.matter_id")}</th>
                                                        <th style={{ width: "15%" }}>{this.props.data.t.read("invoice.cost_type")}</th>
                                                        <th style={{ width: "7%" }}>{this.props.data.t.read("invoice.tax_code")}</th>
                                                        <th style={{ width: "10%" }}>{this.props.data.t.read("invoice.amount")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => this.isItemBillable(item)).map((item) => {
                                                            if (item._view_id == null) {
                                                                item._view_id = this._view_id++;
                                                            }

                                                            return <tr key={"invoice-item-" + item._view_id}>
                                                                <td onClick={e => e.stopPropagation()} className="align-middle">
                                                                    <input type="checkbox" checked={this.state.billablesSelected.indexOf(item) > -1} className="billable-checkbox" value={item._view_id} onChange={e => this.toggleItemSelected("billable", item)} />
                                                                </td>
                                                                <td className="align-middle">
                                                                    <FormTextField format="data" locked={this.state.saving} value={item.Description} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "Description")} />
                                                                </td >
                                                                <td className="align-middle" hidden={this.props.data.config.frontend_invoice.hide_office_in_gl_details_table} >
                                                                    <FormTextField hideNoResults={true} hideAddOperation={true} format="data" locked={this.state.saving} value={item.GLOffice ? item.GLOffice : this.state.invoice.json.GLOffice} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "GLOffice")} searchURL="/offices/search/" />
                                                                </td>
                                                                <td className="align-middle" hidden={this.props.data.config.frontend_invoice.hide_work_date} >
                                                                    <FormTextField format="date" locked={this.state.saving} value={item.WorkDate ? item.WorkDate : this.state.invoice.json.WorkDate} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "WorkDate")} />
                                                                </td>
                                                                <td className="align-middle">
                                                                    <FormTextField hideNoResults={true} hideAddOperation={true} format="data" locked={this.state.saving} value={(item.MatterId || this.state.invoice.json.IsCustom) ? item.MatterId : this.state.invoice.json.MatterID} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "MatterId")} searchURL="/matters/search/" />
                                                                </td>
                                                                <td className="align-middle">
                                                                    <FormTextField hideNoResults={true} hideAddOperation={true} format="data" locked={this.state.saving} value={item.CostType ? item.CostType : this.state.invoice.json.CostType} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "CostType")} searchURL="/cost_type/search/" />
                                                                </td>
                                                                <td className="align-middle">
                                                                    <button onClick={e => this.changeTaxCode(e, item._view_id)}>{item.TaxCode ? item.TaxCode : (this.state.invoice.json.TaxCode ? this.state.invoice.json.TaxCode : "N_IN")}</button>
                                                                </td>
                                                                <td>
                                                                    <div className="position-relative">
                                                                        <FormTextField format="money" locked={this.state.saving} value={item.Total} validateChange={val => { this.updateInvoice(val, "json", "ListItem", item._view_id, "Total"); this.changeTotal(); this.computeTaxes(); }} />
                                                                        <div className="item-actions">
                                                                            <button className="btn btn-sm btn-link" onClick={() => this.confirmRemoveLine(item._view_id)}>
                                                                                <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        null
                                }

                                <div className="mt-5 mr-3">
                                    <h4 className="hidden-actions-container d-flex justify-content-between">
                                        <span>
                                            {this.props.data.t.read("invoice.gl_details")}
                                            <span className="hidden-actions">
                                                <button className="btn btn-sm btn-link" onClick={() => this.addItem(false)}>
                                                    <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                                </button>
                                            </span>
                                        </span>
                                        <div className="text-right">
                                            <button className={"btn btn-secondary btn-sm btn-xs " + (this.state.nonBillablesSelected.length > 0 ? "" : "faded")} onClick={() => this.confirmDeleteAction("non-billable")} >{this.props.data.t.read("delete")}</button>
                                        </div>
                                    </h4>
                                    <table className="table invoice-items-table mt-3 font-xsmall">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "3%" }}>
                                                    <input type="checkbox" id="all-non-billable-checkbox" checked={this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => !this.isItemBillable(item)).length === this.state.nonBillablesSelected.length && this.state.nonBillablesSelected.length > 0} onChange={() => { }} onClick={e => this.toggleSelectAll(e.target, "non-billable")} />
                                                </th>
                                                <th style={{ width: "35%" }}>{this.props.data.t.read("invoice.description")}</th>
                                                <th hidden={this.props.data.config.frontend_invoice.hide_office_in_gl_details_table} style={{ width: "15%" }}>{this.props.data.t.read("invoice.office")}</th>
                                                <th style={{ width: "25%" }}>{this.props.data.t.read("invoice.gl_nat")}</th>
                                                <th style={{ width: "10%" }}>{this.props.data.t.read("invoice.tax_code")}</th>
                                                <th style={{ width: "10%" }}>{this.props.data.t.read("invoice.amount")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.invoice.json.ListItem && this.state.invoice.json.ListItem.filter(item => !this.isItemBillable(item)).map((item) => {
                                                    if (item._view_id == null) {
                                                        this._view_id += 1;
                                                        item._view_id = this._view_id;
                                                    }

                                                    return <tr key={"invoice-gl-item-" + item._view_id}>
                                                        <td onClick={e => e.stopPropagation()} className="align-middle">
                                                            <input type="checkbox" checked={this.state.nonBillablesSelected.indexOf(item) > -1} className="non-billable-checkbox" value={item._view_id} onChange={e => this.toggleItemSelected("non-billable", item)} />
                                                        </td>
                                                        <td className="align-middle">
                                                            <FormTextField format="data" locked={this.state.saving} value={item.Description} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "Description")} />
                                                        </td>

                                                        <td className="align-middle" hidden={this.props.data.config.frontend_invoice.hide_office_in_gl_details_table} >
                                                            <FormTextField hideAddOperation={true} format="data" locked={this.state.saving} value={item.GLOffice ? item.GLOffice : this.state.invoice.json.GLOffice} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "GLOffice")} searchURL="/offices/search/" />
                                                        </td>

                                                        <td className="align-middle">
                                                            <FormTextField hideNoResults={true} hideAddOperation={true} format="data" locked={this.state.saving} value={item.GLNatural ? item.GLNatural : this.state.invoice.json.GLNatural} validateChange={val => this.updateInvoice(val, "json", "ListItem", item._view_id, "GLNatural")} searchURL="/gl/nat/search/" />
                                                        </td>
                                                        <td className="align-middle">
                                                            <button onClick={e => this.changeTaxCode(e, item._view_id)}>{item.TaxCode ? item.TaxCode : (this.state.invoice.json.TaxCode ? this.state.invoice.json.TaxCode : "N_IN")}</button>
                                                        </td>
                                                        <td>
                                                            <div className="position-relative">
                                                                <FormTextField format="money" locked={this.state.saving} value={item.Total} validateChange={val => { this.updateInvoice(val, "json", "ListItem", item._view_id, "Total"); this.changeTotal(); this.computeTaxes(); }} />

                                                                <div className="item-actions">
                                                                    <button className="btn btn-sm btn-link" onClick={() => this.confirmRemoveLine(item._view_id)}>
                                                                        <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <h4 className="mt-5" style={{ display: this.showStatus(tax1) === "flex" || this.showStatus(tax2) === "flex" ? "block" : "none" }}>{this.props.data.t.read("invoice.tax_details")}</h4>

                                <div className="row" style={{ display: this.showStatus(tax1) }}>
                                    <div className="col-md-2">
                                        {this.props.data.t.read("invoice.tax")} 1 :
                                    </div>
                                    <div className="col-3 invoice-value">
                                        <FormTextField format="money" locked={this.state.saving} value={tax1} validateChange={val => { this.updateInvoice(val, "json", "Tax1"); this.computeGrandTotal(); }} />
                                    </div>
                                </div>
                                <div className="row" style={{ display: this.showStatus(tax2) }}>
                                    <div className="col-2">
                                        {this.props.data.t.read("invoice.tax")} 2 :
                                    </div>
                                    <div className="col-3 invoice-value">
                                        <FormTextField format="money" locked={this.state.saving} value={tax2} validateChange={val => { this.updateInvoice(val, "json", "Tax2"); this.computeGrandTotal(); }} />
                                    </div>
                                </div>

                                <h4 className="mt-5">{this.props.data.t.read("comments")}</h4>
                                <div>
                                    <FormTextAreaAutoSaved locked={this.state.saving} value={this.state.invoice.status_details} validateChange={val => this.updateInvoice(val, "status_details")} />
                                </div>

                                <div id="errorMessages" className="errors"></div>
                                {
                                    this.props.data.role !== 'Approver' && this.state.invoice.status !== 'Need_Approval' &&
                                    <div className="mt-5 d-flex justify-content-between">
                                        <div>
                                            <button className="btn btn-sm btn-secondary mr-2" onClick={() => this.setState({ showConfirmRestartProcess: true })}>
                                                {this.props.data.t.read("invoice.restart_processing")}
                                            </button>
                                            <button className="btn btn-sm btn-secondary" onClick={() => this.setState({ showConfirmRestartPostProcess: true })}>
                                                {this.props.data.t.read("invoice.restart_post_processing")}
                                            </button>
                                        </div>
                                        <button disabled={this.state.validating} className="btn btn-primary" onClick={() => this.validateInvoice()}>
                                            {this.props.data.t.read("validate")}
                                            {this.state.validating && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                        </button>
                                        {Utils.userHasPageAccess(this.props.data, "approval") && this.props.data.role !== 'Approver' &&
                                            <button className="btn btn-sm btn-primary" onClick={() => this.setState({ showRequestApproval: true })} disabled={["Validated", "Finalized"].includes(this.state.invoice.status)}>
                                                {this.props.data.t.read("request_approval")}
                                            </button>}
                                    </div>
                                }
                                {
                                    Utils.userHasPageAccess(this.props.data, "approval") && Utils.userHasRole(this.props.data, ['Admin', 'Approver']) &&
                                    <div className="mt-5 d-flex justify-content-around">
                                        <button className="btn  btn-primary" hidden={this.state.invoice.status !== "Need_Approval"} onClick={() => this.approveInvoice()}>
                                            {this.props.data.t.read("approve")}
                                            {this.saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                        </button>

                                        <button className="btn btn-danger " hidden={this.state.invoice.status !== "Need_Approval"} onClick={() => this.setState({ showDisapproval: true })}>
                                            {this.props.data.t.read("disapprove")}
                                        </button>
                                    </div>
                                }
                            </div>
                            :
                            <div className="d-flex flex-column justify-content-between">
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>{this.props.data.t.read("by")}</th>
                                                <th>Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.historyLogs.map(log => {
                                                    return <tr key={log["_id"]} className={this.state.selectedLog === log ? "selected-log" : "row-hover"} onClick={() => this.setState({ selectedLog: log })}>
                                                        <td>{Intl.DateTimeFormat(`${this.props.data.t.lang}-US`, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(new Date(log["created_at"]))}</td>
                                                        <td>{log["event_type"]}</td>
                                                        <td>{log["user"] ? log["user"]["first_name"] + " " + log["user"]["last_name"] : log["username"]}</td>
                                                        <td>{log["notes"]}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.state.selectedLog != null ?
                                        <div className="card m-1 font-xsmall log-details">
                                            <div className="card-body d-flex">
                                                <div className="flex-grow-1 flex-1 p-2 flex-shrink-0 border-right">
                                                    <h5>{this.props.data.t.read("before")}</h5>
                                                    {this.state.selectedLog["diff_backward"]}
                                                </div>
                                                <div className="flex-grow-1 flex-1 p-2 flex-shrink-0">
                                                    <h5>{this.props.data.t.read("after")}</h5>
                                                    {this.state.selectedLog["diff_forward"]}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                    }
                </div>
            </div>
            :
            <div className="loading-container">
                <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
            </div>

    }
}));
