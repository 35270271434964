import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import Modal from './../../components/modal';
import MoneyInput from '../../components/money-input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faArrowDown,
    faArrowUp,
    faPencilAlt,
    faPlus,
    faSpinner,
    faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import AdminNavigationBar from "../../components/admin-navigation-bar";

import Switch from "react-switch";
import Select from 'react-select';
import FilterInputField from "../../components/filter-input-field";
import PaginationControls from "../../components/pagination-controls";
import Checkbox from "../../components/checkbox";
import { fetch } from '../../utils/api-wrapper';

const AdminSuppliers = ({ data }) => {

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [suppliers, setSuppliers] = useState([]);
    const [offices, setOffices] = useState([]);
    const [importFile, setImportFile] = useState(null);
    const [showImportModal, setShowImportModal] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [filterValue, setFilterValue] = useState("");
    const [sorting, setSorting] = useState([{ "id": "names", "desc": false }]);

    const [page, setPage] = useState(1);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [updatedSupplier, setUpdatedSupplier] = useState({});
    const [suppliersDeleteSelection, setSuppliersDeleteSelection] = useState([]);
    const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);
    const [saving, setSaving] = useState(false);
    


    useEffect(() => {
        refreshSuppliers();
        refreshOffices()

    }, [])

    useEffect(() => {
        setPage(1);
        refreshSuppliers();
        refreshOffices()
    }, [pageSize, sorting, filterValue]);

    useEffect(() => {
        refreshSuppliers();
        refreshOffices()
    }, [page]);


    const refreshOffices = () => {
        let start = 0;
        const filters = [{ id: "id_client", value: data.id_client }];
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(start)}&page_size=${pageSize}`;
        fetch(`/offices/${searchData}`, "GET", {},

            response => {
                let fetchedOffices = response.data.offices;
                setOffices([...fetchedOffices]);
                setLoading(false);
            });
    }

    const refreshSuppliers = () => {
        let start = (page - 1) * pageSize;
        const filters = [];
        if (filterValue && filterValue.trim() !== "") {
            filters.push({ id: "search", value: filterValue.trim() });
        }
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(start)}&page_size=${pageSize}`;
        fetch(`/suppliers/${searchData}`, "GET", {},

            response => {
                let fetchedSuppliers = response.data.suppliers;
                setSuppliers(fetchedSuppliers);
                setTotalRowCount(response.data.meta.totalRowCount)
                setLoading(false);
            });
    }

    const handleSupplierSubmit = (e) => {
        e.preventDefault();
        setShowSupplierModal(false);
        if (updatedSupplier._id) {
            updateSupplier();
        } else {
            createSupplier();
        }
    };

    const createSupplier = () => {
        let formData = new FormData();
        formData.append("names", JSON.stringify(updatedSupplier.names));
        formData.append("zipcode", updatedSupplier.zipcode);
        formData.append("budget", updatedSupplier.budget);
        formData.append("active", updatedSupplier.active);
        formData.append("country", updatedSupplier.country);
        formData.append("city", updatedSupplier.city);
        formData.append("state", updatedSupplier.state);
        formData.append("address", updatedSupplier.address);
        formData.append("gl_code", updatedSupplier.gl_code);
        formData.append("supplier_number", updatedSupplier.supplier_number);
        formData.append("need_moderation", updatedSupplier.need_moderation);

        if (data.config.type === "legal") {
            formData.append("is_billable", updatedSupplier.IsBillable);
            formData.append("tax_code", updatedSupplier.TaxCode);
            formData.append("cost_type", updatedSupplier.cost_type);
        }
        fetch("/suppliers/", "POST", formData,
            () => {
                setSuccessMessage(data.t.read("supplier_created_successfully"));
                refreshSuppliers();
            },
            () => {
                setErrorMessage(data.t.read("failed_to_create_supplier"));
            }
        );
    };

    const updateSupplier = () => {
        let formData = new FormData();
        formData.append("names", JSON.stringify(updatedSupplier.names));
        formData.append("zipcode", updatedSupplier.zipcode);
        formData.append("budget", updatedSupplier.budget);
        formData.append("active", updatedSupplier.active);
        formData.append("country", updatedSupplier.country);
        formData.append("city", updatedSupplier.city);
        formData.append("state", updatedSupplier.state);
        formData.append("address", updatedSupplier.address);
        formData.append("gl_code", updatedSupplier.gl_code);
        formData.append("supplier_number", updatedSupplier.supplier_number);
        formData.append("need_moderation", updatedSupplier.need_moderation);

        if (data.config.type === "legal") {
            formData.append("is_billable", updatedSupplier.IsBillable);
            formData.append("tax_code", updatedSupplier.TaxCode);
            formData.append("cost_type", updatedSupplier.cost_type);
        }

        const supplierId = updatedSupplier._id?.$oid;

        fetch(`/suppliers/${supplierId}`, "PUT", formData,

            () => {
                refreshSuppliers();
                setSuccessMessage(data.t.read("supplier_updated_successfully"));
            },
            () => {
                setErrorMessage(data.t.read("failed_to_update_supplier"));
            }
        );
    };

    const openNewSupplierModal = () => {
        setUpdatedSupplier({
            names: [""],
            zipcode: "",
            id_client: data.id_client,
            country: "",
            active: true,
            need_moderation: false,
            TaxCode: "T_IN",
            IsBillable: true
        });
        setShowSupplierModal(true);
    };

    const openUpdateSupplierModal = (supplier) => {
        setUpdatedSupplier({ ...supplier });

        setShowSupplierModal(true);
    };

    const cancelSupplierModal = () => {
        setUpdatedSupplier({});
        setShowSupplierModal(false);
    };

    const deleteSuppliers = () => {
        fetch("/suppliers/", "DELETE", { "supplier_ids": suppliersDeleteSelection },

            () => {
                refreshSuppliers();
                setSuppliersDeleteSelection([])
                setShowDeleteSupplierModal(false)
            },
            (error) => {
                setErrorMessage(data.t.read("failed_to_delete"))
                setLoading(false);
            }
        );
    }

    const cancelDeleteSuppliersModal = () => {
        setShowDeleteSupplierModal(false);
        setSuppliersDeleteSelection([])
    }

    const prepareImport = (f) => {
        if (f.length > 0) {
            setImportFile(f[0])
        }
    }

    const processImport = () => {
        if (importFile != null) {
            const data = new FormData();
            data.append("csv", importFile);

            fetch("/suppliers/import", "POST", data,
                response => {
                    setShowImportModal(false);
                    if (response.data.message.length > 0) {
                        setErrorMessage(JSON.stringify(response.data.message))
                        refreshSuppliers()
                    } else refreshSuppliers();
                },
                null, true)
        }
    }


    const isAllSuppliersSelected = () => {
        if (!suppliers || !suppliers.length) {
            return false;
        }
        return suppliers.every(supplier => suppliersDeleteSelection.includes(supplier._id?.$oid));
    }

    const isSupplierSelected = (supplier) => {
        return suppliersDeleteSelection.includes(supplier._id?.$oid);
    }

    const toggleAllSuppliersSelected = () => {
        setSuppliersDeleteSelection(isAllSuppliersSelected() ?
            suppliersDeleteSelection.filter(id => !suppliers.some(supplier => supplier._id?.$oid === id))
            :
            [...suppliersDeleteSelection, ...suppliers.map((supplier) => supplier._id?.$oid).filter((id) => !suppliersDeleteSelection.includes(id))]
        );
    }

    const sortTable = (name) => {
        const sorter = sorting.find(s => s.id === name)
        if (sorter === undefined)
            return setSorting([{ "id": name, "desc": true }])

        if (sorter.desc)
            return setSorting([{ "id": name, "desc": false }])

        else
            return setSorting([])
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (rows) => {
        setPage(1)
        setPageSize(rows)
    }

    //pagination logic
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + suppliers.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - suppliers.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };
    return (
        <div className="card pl-4 pr-4 pb-3 rounded-lg">
            <AdminNavigationBar data={data} adminPage={"suppliers"}></AdminNavigationBar>
            <div>
                {!loading ?
                    <div>
                        <div>
                            <div className='pagination-row'>
                                <FilterInputField
                                    filterValue={filterValue} setFilterValue={setFilterValue} placeholder={""}>
                                </FilterInputField>
                                <PaginationControls
                                    page={page}
                                    totalPages={totalPages}
                                    indexOfFirstItem={indexOfFirstItem}
                                    indexOfLastItem={indexOfLastItem}
                                    handlePageChange={handlePageChange}
                                    totalItems={totalRowCount}
                                    data={data}
                                    handleRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPage={pageSize}
                                ></PaginationControls>
                            </div>
                            <div className="d-flex justify-content-end align-items-center px-4 py-2">
                                <div className="d-flex">
                                    <button
                                        type="button"
                                        className=' pointer btn btn-secondary btn-sm ml-2'
                                        disabled={saving || suppliersDeleteSelection.length === 0}
                                        onClick={() => setShowDeleteSupplierModal(true)}
                                    >
                                        {data.t.read("delete") + " (" + suppliersDeleteSelection.length + ")"}
                                    </button>
                                    <button
                                        onClick={() => setShowImportModal(!showImportModal)}
                                        type="button"
                                        className='btn btn-primary btn-sm ml-2'>
                                        {data.t.read("import")}
                                    </button>
                                    <button
                                        onClick={() => openNewSupplierModal()} type="button"
                                        className='btn btn-primary btn-sm ml-2'>
                                        {data.t.read("add")}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-2 card rounded-lg admin-table">
                                <table className='table table-hover font-small'>
                                    <thead>

                                    <tr>
                                        <th className="">
                                            <Checkbox disabled={!suppliers || !suppliers.length}
                                                      isChecked={isAllSuppliersSelected()}
                                                      onChange={toggleAllSuppliersSelected}/>
                                        </th>
                                        <th
                                            className="pointer col-3"
                                            onClick={() => sortTable("names")}>
                                            {data.t.read("name")}
                                            {sorting.find(s => s.id === "names") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "names").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th
                                            className="pointer col-2"
                                            onClick={() => sortTable("zipcode")}>
                                            {data.t.read("supplier_table.postal_code")}
                                            {sorting.find(s => s.id === "zipcode") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "zipcode").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th
                                            className="pointer col-1"
                                            onClick={() => sortTable("country")}>
                                            {data.t.read("country")}
                                            {sorting.find(s => s.id === "country") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "country").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th
                                            className="pointer col-1">
                                            {data.t.read("supplier_table.created_by")}
                                            {sorting.find(s => s.id === "name") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "name").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th
                                            className="pointer col-2"
                                            onClick={() => sortTable("created_at")}>
                                            {data.t.read("supplier_table.created_on")}
                                            {sorting.find(s => s.id === "created_at") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "created_at").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th
                                            className="pointer col-2"
                                            onClick={() => sortTable("updated_at")}>
                                            {data.t.read("supplier_table.last_modified_on")}
                                            {sorting.find(s => s.id === "updated_at") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "updated_at").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th className="pointer">
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        suppliers.map(supplier => {
                                            return <tr key={supplier._id?.$oid}
                                                       className={supplier.need_moderation ? "bg-info" : ""}>
                                                <td
                                                    className="">

                                                    <Checkbox isChecked={isSupplierSelected(supplier)}
                                                              onChange={() => isSupplierSelected(supplier) ? setSuppliersDeleteSelection(suppliersDeleteSelection.filter(x => x !== supplier._id?.$oid)) : setSuppliersDeleteSelection([...suppliersDeleteSelection, supplier._id?.$oid])}/>
                                                </td>
                                                <td onClick={() => openUpdateSupplierModal(supplier)}
                                                    className="col-3">
                                                    {supplier.names[0]}
                                                </td>
                                                <td onClick={() => openUpdateSupplierModal(supplier)}
                                                    className="col-2">
                                                    {supplier.zipcode}
                                                </td>
                                                <td onClick={() => openUpdateSupplierModal(supplier)}
                                                    className="col-1">
                                                    {supplier.country}
                                                </td>
                                                <td onClick={() => openUpdateSupplierModal(supplier)}
                                                    className="col-1">
                                                    {data.client_name}
                                                </td>
                                                <td onClick={() => openUpdateSupplierModal(supplier)}
                                                    className="col-2">
                                                    {supplier.created_at ? Intl.DateTimeFormat(`${data.t.lang}-US`, {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: false,
                                                    }).format(new Date(supplier.created_at?.$date)) : ""}
                                                </td>
                                                <td onClick={() => openUpdateSupplierModal(supplier)}
                                                    className="col-2">
                                                    {supplier.updated_at ? Intl.DateTimeFormat(`${data.t.lang}-US`, {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: false,
                                                    }).format(new Date(supplier.updated_at?.$date)) : ""}
                                                </td>
                                                <td className="col-2">
                                                    {<FontAwesomeIcon className="mr-3 ml-2"
                                                                      onClick={() => openUpdateSupplierModal(supplier)}
                                                                      icon={faPencilAlt}/>}
                                                    {<FontAwesomeIcon style={{color: 'red'}}
                                                                      onClick={() => {
                                                                          setSuppliersDeleteSelection([supplier._id?.$oid]);
                                                                          setShowDeleteSupplierModal(true);
                                                                      }}
                                                                      icon={faWindowClose}/>}
                                                </td>
                                            </tr>

                                        })
                                    }
                                    {
                                        emptyRows.map((_, idx) => (
                                            <tr key={`empty-${idx}`}>
                                                <td colSpan="8">&nbsp;</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {errorMessage != null && (
                            <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>

                                <p style={{whiteSpace: 'pre-line'}} className='text-danger'>{errorMessage}</p>
                            </Modal>
                        )}
                        {successMessage != null && (
                            <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                                {successMessage}
                            </Modal>
                        )}
                        {showImportModal && (
                            <Modal width="50vw" title={data.t.read("importation")}
                                   cancelAction={() => setShowImportModal(false)} okAction={() => processImport()}
                                   okText={data.t.read("import")} cancelText={data.t.read("cancel")}>
                                <div>
                                    {data.t.read("supplier_table.csv_file_format")}
                                    <div className='card p-2'>
                                        {data.t.read("supplier_table.supplier_details_string")}<br/>
                                        {data.t.read("supplier_table.supplier_details_string")}<br/>
                                        {data.t.read("supplier_table.supplier_details_string")}<br/>
                                    </div>
                                    <div className='mt-2'>
                                        <input className='form-control' type="file" id="import-csv-file"
                                               onChange={f => prepareImport(f.target.files)}/>
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {
                            showSupplierModal && (
                                <form method="post"
                                      onSubmit={handleSupplierSubmit}
                                >
                                    <Modal
                                        width="60vw"
                                        title={updatedSupplier.names[0] || data.t.read("new_supplier")}
                                        okIsSubmit={true} cancelAction={cancelSupplierModal}
                                        okText={data.t.read("save")}
                                        cancelText={data.t.read("cancel")}>
                                        <div className="form-group">
                                            <h5>
                                                {data.t.read("supplier_table.names")}

                                                <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1' type="button"

                                                        onClick={() => setUpdatedSupplier({
                                                            ...updatedSupplier,
                                                            names: [...updatedSupplier.names, ""]
                                                        })}>
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </button>
                                            </h5>
                                            {
                                                updatedSupplier.names.map((name, i) => (
                                                    <div
                                                        className="d-flex justify-content-between form-control align-items-center mb-2 p-2"
                                                        key={i}>
                                                        <input
                                                            required
                                                            placeholder={"Nom"}
                                                            type="text" className="form-control border-0" name="name"

                                                            value={name} style={{backgroundColor: 'transparent'}}
                                                            onChange={(event) => setUpdatedSupplier({
                                                                ...updatedSupplier,
                                                                names: updatedSupplier.names.map((item, index) => index === i ? event.target.value : item)
                                                            })}
                                                        />
                                                        <FontAwesomeIcon icon={faWindowClose} type="button" color={"red"}

                                                                         onClick={() => setUpdatedSupplier({
                                                                             ...updatedSupplier,
                                                                             names: updatedSupplier.names.filter((x, index) => index !== i)
                                                                         })}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="form-group">
                                            <h5 className='mt-4'>
                                                Codes
                                            </h5>
                                            <div className='mt-1'>
                                                <input placeholder={data.t.read("gl_codes")}

                                                       className='form-control' type="text"
                                                       value={updatedSupplier.gl_code ?? ""}
                                                       onChange={e => setUpdatedSupplier({
                                                           ...updatedSupplier,
                                                           gl_code: e.target.value
                                                       })}
                                                />
                                            </div>
                                            {data.config.type === "legal" && <div className='mt-1'>
                                                <input placeholder={data.t.read("supplier_table.cost_type")}
                                                       className='form-control' type="text"
                                                       value={updatedSupplier.cost_type ? updatedSupplier.cost_type : ""}
                                                       onChange={e => setUpdatedSupplier({
                                                           ...updatedSupplier,
                                                           cost_type: e.target.value
                                                       })}/>
                                            </div>}
                                            <div className='mt-1'>
                                                <input readOnly={data.config.engine === "juris"}
                                                       required={data.config.engine !== "juris"}
                                                       placeholder={data.t.read("supplier_table.supplier_number")}
                                                       className='form-control' type="text"
                                                       value={updatedSupplier.supplier_number ?? ""}
                                                       onChange={e => setUpdatedSupplier({
                                                           ...updatedSupplier,
                                                           supplier_number: e.target.value
                                                       })}/>
                                            </div>

                                            <div className="form-group">
                                                <h5 className='mt-4'>Budget</h5>
                                                <div>
                                                    <MoneyInput value={updatedSupplier.budget}

                                                                onChange={val => setUpdatedSupplier({
                                                                    ...updatedSupplier,
                                                                    budget: val
                                                                })}
                                                                locale={`${data.t.lang}-${data.t.lang.toUpperCase()}`}
                                                                placeholder="budget"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <h5 className='mt-4'>
                                                    {data.t.read("supplier_table.other_information")}
                                                </h5>
                                                {data.config.engine === "juris" &&
                                                    <div className='mt-1'>
                                                        <Select
                                                            name="offices"
                                                            value={updatedSupplier?.office ?? ""}
                                                            options={
                                                                offices
                                                                    ? offices.map(o => ({
                                                                        label: o.name,
                                                                        value: o._id.$oid
                                                                    }))
                                                                    : []
                                                            }
                                                            styles={styleSelect}
                                                            classNamePrefix="select"
                                                            onChange={(choice) => setUpdatedSupplier({
                                                                ...updatedSupplier,
                                                                office: choice
                                                            })}
                                                            placeholder={data.t.read("invoice.office")}
                                                        />
                                                    </div>
                                                }
                                                <div className='mt-1'>
                                                    <input
                                                        required
                                                        placeholder={data.t.read("supplier_table.address")}
                                                        className='form-control' type="text"
                                                        value={updatedSupplier.address ?? ""}
                                                        onChange={e => setUpdatedSupplier({
                                                            ...updatedSupplier,
                                                            address: e.target.value

                                                        })}/>
                                                </div>
                                                <div className='mt-1 d-flex justify-content-between'>
                                                    <input required
                                                           placeholder={data.t.read("supplier_table.postal_code_zip")}
                                                           className='form-control' type="text"
                                                           value={updatedSupplier.zipcode ?? ""}
                                                           onChange={e => setUpdatedSupplier({
                                                               ...updatedSupplier,
                                                               zipcode: e.target.value
                                                           })}/>
                                                    <input required placeholder={data.t.read("country")}
                                                           className='form-control' type="text"
                                                           value={updatedSupplier.country ?? ""}
                                                           onChange={e => setUpdatedSupplier({
                                                               ...updatedSupplier,
                                                               country: e.target.value
                                                           })}/>
                                                    <input
                                                        required={data.config.engine === "juris"}
                                                        placeholder={data.t.read("state")}
                                                        className='form-control' type="text"
                                                        value={updatedSupplier.state ?? ""}
                                                        onChange={e => setUpdatedSupplier({
                                                            ...updatedSupplier,
                                                            state: e.target.value

                                                        })}/>
                                                    <input
                                                        required={data.config.engine === "juris"}
                                                        placeholder={data.t.read("city")}
                                                        className='form-control' type="text"
                                                        value={updatedSupplier.city ?? ""}
                                                        onChange={e => setUpdatedSupplier({
                                                            ...updatedSupplier,
                                                            city: e.target.value

                                                        })}/>

                                                </div>
                                                {
                                                    data.config.type === "legal" ?
                                                        <>
                                                            <div className='mt-4'>
                                                                <h5>{data.t.read("supplier.tax_code")}</h5>
                                                                <select value={updatedSupplier.TaxCode ?? "T_IN"}
                                                                        placeholder={data.t.read("supplier.tax_code")}
                                                                        className='form-control'
                                                                        onChange={e => setUpdatedSupplier({
                                                                            ...updatedSupplier,
                                                                            TaxCode: e.target.value
                                                                        })}>

                                                                    <option
                                                                        value="N_IN">{data.t.read("supplier.choices.tax_code.n_in")}</option>
                                                                    <option
                                                                        value="T_IN">{data.t.read("supplier.choices.tax_code.t_in")}</option>
                                                                </select>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <h5>{data.t.read("supplier.is_billable")}?</h5>
                                                                <select value={updatedSupplier.IsBillable ?? true}

                                                                        placeholder={data.t.read("supplier.is_billable")}
                                                                        className='form-control'
                                                                        onChange={e => setUpdatedSupplier({
                                                                            ...updatedSupplier,
                                                                            IsBillable: e.target.value
                                                                        })}>
                                                                    <option
                                                                        value={true}>{data.t.read("supplier.choices.is_billable.true")}</option>
                                                                    <option
                                                                        value={false}>{data.t.read("supplier.choices.is_billable.false")}</option>
                                                                </select>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <div className='mt-4 ml-2'>
                                                    <label>
                                                        <input type="checkbox"

                                                               defaultChecked={updatedSupplier.need_moderation}
                                                               onClick={() => setUpdatedSupplier({
                                                                   ...updatedSupplier,
                                                                   need_moderation: !updatedSupplier.need_moderation
                                                               })}/> {data.t.read("supplier_table.needs_moderation")}

                                                    </label>
                                                </div>
                                                <div className='d-flex mt-3'>
                                                    <div
                                                        className='col-2 mt-2'>{data.t.read("supplier_table.active")}</div>
                                                    <div className='col-3'>
                                                        <Switch className='switch'

                                                                checked={updatedSupplier.active}
                                                                width={70}
                                                                onColor="#F39800"
                                                                onHandleColor="#2693e6"
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                onChange={active => setUpdatedSupplier({
                                                                    ...updatedSupplier,
                                                                    active: active
                                                                })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </form>
                            )}
                        {showDeleteSupplierModal && (
                            <Modal
                                title={"Delete"}
                                okAction={deleteSuppliers}
                                cancelAction={cancelDeleteSuppliersModal}
                                okText={data.t.read("delete")}
                                cancelText={data.t.read("cancel")}>
                                <p className='text-danger'>{suppliersDeleteSelection.length === 1 ? data.t.read("supplier_table.delete_supplier") : data.t.read("supplier_table.delete_suppliers")}</p>
                            </Modal>
                        )}
                    </div>
                    :
                    <div className="d-flex loading-container w-100">

                        <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>

                    </div>
                }
            </div>
        </div>
    )
}

export default inject('data')(observer(AdminSuppliers));
