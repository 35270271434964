import {useEffect, useState} from "react";
import axios from "axios";
import iconv from 'iconv-lite';
import closeIcon2 from '../assets/img/icon-close2.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faAngleRight,
    faBars,
    faPlusSquare,
    faSpinner,
    faSync,
    faTimes,
    faWindowClose
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import FormTextAreaAutoSaved from './form-text-area-auto-saved';
import FormTextField from './form-textfield';
import Modal from './modal';
import Checkbox from "../components/checkbox";
import CustomResizable from './custom-resizable';
import Utils from "../utils/utils";

import {fetch} from '../utils/api-wrapper';
import Switch from "react-switch";

const Expense = ({ data, account_id, selected, close, account_status }) => {
    const [expensesAccount, setExpensesAccount] = useState();
    const source = axios.CancelToken.source();
    const [pdfBuff, setPdfBuff] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [expense, setExpense] = useState(selected);
    const [currentView, setCurrentView] = useState("data");
    const [saving, setSaving] = useState(false);
    const [showDisapproval, setShowDisapproval] = useState(false);
    const [validating, setValidating] = useState(false);
    const [causeDenial, setCauseDenial] = useState(null);
    const [action, setAction] = useState();
    const [savingError, setSavingError] = useState(null);
    const [showConfirmRestartProcess, setShowConfirmRestartProcess] = useState("false");
    const [confirmDeleteViewId, setConfirmDeleteViewId] = useState(null);
    const [billablesSelected, setBillablesSelected] = useState([]);
    const [nonBillablesSelected, setNonBillablesSelected] = useState([]);
    const [confirmDeleteItems, setConfirmDeleteItems] = useState(null);
    const [imputations, setImputations] = useState([]);
    const [rates, setRates] = useState({});
    // make it configurable by client
    const taxeCodes = ["T_IN", "TVH_IN", "N_IN", "TPS_IN", "TVH-ON_IN", "BC_IN"]
                        .filter(t => (data.config.type === "legal" || t !== "TVH-ON_IN"))
                        .filter(t => (data.config.engine === "demo" || t !== "BC_IN"))

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important",
            padding: data.isMobile ? "0" : baseStyles.padding,
        })
    };

    useEffect(() => () => source.cancel("Expense unmounted"), []);
    useEffect(() => {
        fetchExpensesAccount()
        fetchImputations()
        fetchRates()
    }, []);

    const fetchExpensesAccount = () => {
        const searchData = `?account_id=${encodeURIComponent(account_id)}`;

        fetch(`/expenses_account/${searchData}`, "GET", {},
            (response) => {
                setExpensesAccount(response.data.expenses_account);
            }
            ,
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchExpensesAccount();
            }
            , false, source.token);
    }

    useEffect(() => {
        if (saving === true)
            updateExpense()
    }
        , [saving])

    useEffect(() => {

        if (data.isMobile || ['mileage', 'perdiem'].includes(expense.json?.type))
            return;

        fetch(`/invoice/pdf/${account_id}_${expense.checksum}`, "GET", {},
            response => {
                let buf = null;
                if (response.data.pdf != null) {
                    buf = iconv.encode(response.data.pdf, 'iso-8859-1');
                    let bb = new Blob([buf], { type: "application/pdf" });
                    buf = window.URL.createObjectURL(bb);
                    setPdfBuff(buf);
                }
            },
            null, false, source.token);

    }, [expense.checksum])

    const fetchRates = () => {
        fetch("/expense_rates/", "GET", {}, (response) => {
            const fetchedRates = response.data?.rates;
            setRates(fetchedRates);
        } );
    };

    const fetchImputations = () => {
        const searchData = `?filters=${encodeURIComponent(JSON.stringify([]))}&sorting=${encodeURIComponent(JSON.stringify([]))}&start=${encodeURIComponent(0)}`;
        fetch(`/imputations/${searchData}`, "GET", {},
            response => {
                let fetchedImputations = response.data.imputations;
                setImputations(fetchedImputations);
            });
    }
    const showStatus = (value) => {
        return (value != null && value !== "") || data.config.frontend_invoice == null || !data.config.frontend_invoice.hide_na_fields ? "flex" : "none";
    }

    const updateExpense = (action = 'update') => {
        const formData = new FormData();
        setAction(action);
        formData.append("json", JSON.stringify(expense.json));
        formData.append("status_details", expense.status_details);
        formData.append("status", expense.status);
        fetch("/expenses/" + account_id + "/" + expense.checksum, "PUT", formData,
            response => {
                setSaving(false);
                setExpense(response.data.expense);
                fetchExpensesAccount();
            },
            error => {
                setSaving(false);
                console.error(error);
                setSavingError(data.t.read("internal_saving_error"))
            })
    }

    const validateExpense = () => {
        setValidating(true);
        const formData = new FormData();
        formData.append("expense", JSON.stringify(expense));
        fetch(`/expenses/validate/${account_id}/${expense.checksum}`, "PUT", formData,
            response => {
                setValidating(false);
                setExpense(response.data.expense);
                getNextExpense(true, true);
                fetchExpensesAccount();
            },
            error => {
                setValidating(false);
                console.error(error);
                setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);

            })
    }

    const restartProcess = () => {
        fetch(`/expenses/restart-processing/${account_id}/${expense.checksum}`, "GET", {},
            response => {
                close();
            },
            null, false, source.token);
    }

    const isItemBillable = (item) => {
        return item.IsBillable;
    }

    const hasBillableItems = () => {
        return expense.json && expense.json.ListItem && expense.json.ListItem.filter(item => isItemBillable(item)).length > 0;
    }

    const hasUnBillableItems = () => {
        return expense.json && expense.json.ListItem && expense.json.ListItem.filter(item => !isItemBillable(item)).length > 0;
    }

    const addItem = (billable = false) => {
        const listItem = expense.json.ListItem ?? [];

        const imputation = imputations.find(x => x.category === expense?.json?.Category);
        listItem.push({
            _view_id: listItem.length,
            IsBillable: billable ? 1 : 0,
            Total: 0,
            ...(billable ? { CostType: imputation?.cost_type, MatterId: "" } : { GLNatural: imputation?.gl_natural }),
        });

        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
    }

    const toggleItemSelected = (section, item) => {
        let list = section === "billable" ? billablesSelected : nonBillablesSelected;
        let idx = list.findIndex(it => it._view_id === item._view_id);

        if (idx !== -1) {
            list.splice(idx, 1)
        }
        else {
            list.push(item);
        }

        section === "billable" ? setBillablesSelected([...list]) : setNonBillablesSelected([...list]);

    }

    const deleteItem = (viewId) => {
        const listItem = expense.json.ListItem.filter(item => item._view_id !== viewId)
                                            .map((item, index) => ({
                                                                    ...item, // Spread the existing properties
                                                                    "_view_id": index,}
                                                                    ));
        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
        setConfirmDeleteViewId(null);
        setNonBillablesSelected([])
        setBillablesSelected([])
    }

    const confirmDeleteAction = (section) => {
        let list = section === "billable" ? billablesSelected : nonBillablesSelected;
        setConfirmDeleteItems(list);
        section === "billable" ? setBillablesSelected([]) : setNonBillablesSelected([]);
    }

    const deleteItems = () => {
        const listItem = expense.json.ListItem.filter(item => !confirmDeleteItems.includes(item))
                                            .map((item, index) => ({
                                                                    ...item, // Spread the existing properties
                                                                    "_view_id": index,}
                                                                    ));
        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
        setNonBillablesSelected([])
        setBillablesSelected([])
        setConfirmDeleteItems(null);
    }

    const updateItem = (item) => {
        const listItem = expense.json.ListItem;
        const index = listItem.findIndex(it => it._view_id === item._view_id);
        listItem[index] = item;
        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
    }

    const toggleSelectAll = (sectionName) => {
        if (sectionName === "non-billable")
            setNonBillablesSelected(expense.json.ListItem && expense.json.ListItem.filter(item => !isItemBillable(item)).length !== nonBillablesSelected.length ? [...expense.json.ListItem.filter(item => !isItemBillable(item))] : [])
        else
            setBillablesSelected(expense.json.ListItem && expense.json.ListItem.filter(item => isItemBillable(item)).length !== billablesSelected.length ? [...expense.json.ListItem.filter(item => isItemBillable(item))] : [])
    }


    /**
     * when getting the next expense we call ask to remove it from list 
     * @param {boolean} remove 
     * @param {boolean} forward 
     */
    const getNextExpense = (remove = true, forward = true) => {
        let nextIndex;
        setBillablesSelected([])
        setNonBillablesSelected([])
        const indexCurrentInvoice = expensesAccount?.expenses.findIndex(
            xpen => xpen.checksum === expense.checksum
        );

        if (forward === true) {
            if (indexCurrentInvoice + 1 <  expensesAccount?.expenses.length) {
                nextIndex = indexCurrentInvoice + 1
            }
            else {
                nextIndex = 0
            }
        }

        else if (forward === false) {
            if (indexCurrentInvoice - 1 >= 0)
                nextIndex = indexCurrentInvoice - 1;
            else
                nextIndex =  expensesAccount?.expenses.length - 1;
        }

        if (remove === true) {
            expensesAccount?.expenses.splice(indexCurrentInvoice, 1);
            nextIndex = indexCurrentInvoice;
        }

        if ( expensesAccount?.expenses.length > nextIndex) {
            setExpense( expensesAccount?.expenses[nextIndex]);
        }
        else
            close();
    }

    const getNextTaxCode = (taxCode) => {
        let indexNextCode = taxeCodes.findIndex(el => el === taxCode) + 1;
        indexNextCode = indexNextCode >= taxeCodes.length ? 0 : indexNextCode;
        return taxeCodes[indexNextCode];
    }

    const updateExpenseStatus = (choice) => {
        if (choice.value === "Validated") {
            validateExpense();
            return;
        }
        if (choice.value === "Approved") {
            approveExpense();
            return;
        }
        if (choice.value === "Denied") {
            setShowDisapproval(true);
            return;
        }
        setExpense(prev => ({ ...prev, status: choice.value }));
        setSaving(true);
    };

    const approveExpense = () => {
        setExpense(prev => ({ ...prev, status: 'Approved' }));
        setAction('approving');
        setSaving(true);
    }

    const disApproveExpense = () => {
        setAction('disApproving');
        setExpense(prev => ({
            ...prev,
            status: 'Denied',
            status_details: new Date().toLocaleString() + ' : ' + data.firstName + ' ' + data.lastName + ' : ' + causeDenial + '\n' + expense?.status_details
        }
        ));
        setShowDisapproval(false);
        setSaving(true);
    }

    const updateType = (choice) => {
        setExpense(prev => ({ ...prev, json: { ...prev.json, type: choice?.value, rate: choice.rate } }));
        setSaving(true);
    }

    const updateCategory = (choice) => {
        const imputation = imputations.find(x => x.category === choice.value);

        const updated_expense = {
            ...expense, json: {
                ...expense.json, Category: choice.value,
                ...(expense.json.ListItem && imputation && {
                    ListItem: expense.json.ListItem.map(item => {
                        if ([false, null, 0].includes(item.IsBillable)) {
                            return {...item, GLNatural: imputation.gl_natural}; // Update GLNatural
                        } else if ([true, 1].includes(item.IsBillable)) {
                            return {...item, CostType: imputation.cost_type}; // Update CostType
                        }
                        return item;
                    }),
                }),
            },
        };

        setExpense(updated_expense);
        setSaving(true)
    }

    return expense == null ?
        <div className="loading-container">
            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
        </div>
        :
        <div className="invoice bg-white">
            {
                pdfBuff !== null && !['mileage', 'perdiem'].includes(expense.json?.type) &&
                <CustomResizable type="Invoice">
                    <div className="image-section position-relative">
                        <div className="d-flex main-tabs">
                            <div>
                                <div className="tab-title p-3 pointer " onClick={() => setMenuVisible(true)}>
                                    <FontAwesomeIcon icon={faBars} style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                            {
                                menuVisible ?
                                    <div className="left-menu-section">
                                        <div>
                                            <div className="d-flex main-tabs mb-4">
                                                <div className="tab-title text-secondary p-3 pointer" onClick={() => setMenuVisible(false)}>
                                                    <FontAwesomeIcon className="text-secondary" icon={faTimes} style={{ fontSize: "25px" }} />
                                                </div>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className="mock-link text-danger" >
                                                            {data.t.read("delete")}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <object className="pdf"  width="100%" height="100%" type="application/pdf" data={pdfBuff} aria-label="PDF"></object>
                    </div>
                </CustomResizable>
            }
            {
                <div className={data.isMobile ? "content-section d-flex flex-column mb-5" : "content-section d-flex flex-column"}>
                    <div className="d-flex main-tabs">
                        {
                             expensesAccount?.expenses &&  expensesAccount?.expenses.length > 1 &&
                            <div className='pointer icon-box'>
                                <FontAwesomeIcon disabled={saving} icon={faAngleLeft} className="fa-2x pointer" color='#F39800' onClick={() => getNextExpense(false, false)} />
                            </div>
                        }
                        <div className={"flex-grow-1 p-3 tab-title " + (currentView === "data" ? "tab-selected" : "")} onClick={() => setCurrentView("data")}>
                            {data.t.read("data")}
                        </div>
                        <div className="p-3 pointer" onClick={() => close()}>
                            <img src={closeIcon2} alt="Close" />
                        </div>
                        {
                             expensesAccount?.expenses &&  expensesAccount?.expenses.length > 1 &&
                            <div className='pointer icon-box'>
                                <FontAwesomeIcon disabled={saving} icon={faAngleRight} className="fa-2x pointer" color='#F39800' onClick={() => getNextExpense(false, true)} />
                            </div>
                        }
                    </div>
                    <div className="p-4 d-flex flex-column flex-grow-1">
                        <div className="row mb-1">
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 align-self-center font-weight-bold"}>
                                {data.t.read("invoice.status_txt")} :
                            </div>
                            <Select
                                name="status"
                                value={expense.status ? { value: expense.status, label: data.t.read(`status.${expense.status.toLowerCase()}`) } : null}
                                options={data.config.invoice_status.map(s => ({ label: data.t.read(`status.${s.toLowerCase()}`), value: s }))}
                                styles={styleSelect}
                                className={data.isMobile ? "col-6 invoice-value pl-1" : "pl-1 col-3 invoice-value"}
                                classNamePrefix="select"
                                onChange={updateExpenseStatus}
                            />
                        </div>
                        <div className="row mb-1" hidden={!['mileage', 'perdiem'].includes(expense.json?.type)}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                Type :
                            </div>
                            <Select
                                name="type"
                                value={expense.json?.type ? { value: expense.json?.type, label: data.t.read(expense.json?.type) } : null}
                                options={[{ label: data.t.read('mileage'), value: 'mileage', rate: (rates?.mileage_details?.rate || 0.7) }, { label: data.t.read('perdiem'), value: 'perdiem', rate: (rates?.perdiem_details?.rate || 75) }]}
                                styles={styleSelect}
                                className={data.isMobile ? "col-6 invoice-value pl-1" : " pl-1 col-3 invoice-value"}
                                classNamePrefix="select"
                                onChange={updateType}
                                placeholder={'Type'}
                            />
                        </div>

                        <div className="row" hidden={expense.json?.type !== 'mileage'}>
                            <div className={data.isMobile ? "col-12 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("address_origin")} :
                            </div>
                            <div className={data.isMobile ? "col-11 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField searchParams={{ limit: 5 }} hideNoResults={true} hideAddOperation={true} format="data" locked={validating || saving} value={expense.json?.AddressOrigin ?? ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, AddressOrigin: val } })); setSaving(true) }} searchURL="/places/search/" />
                            </div>
                        </div>

                        <div className="row" hidden={expense.json?.type !== 'perdiem'}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("number_of_days")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="number" locked={validating || saving} value={expense.json?.quantity} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, quantity: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" hidden={expense.json?.type !== 'mileage'}>
                            <div className={data.isMobile ? "col-12 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("address_destination")} :
                            </div>
                            <div className={data.isMobile ? "col-11 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField searchParams={{ limit: 5 }} hideNoResults={true} hideAddOperation={true} format="data" locked={validating || saving} value={expense.json?.AddressDestination ?? ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, AddressDestination: val } })); setSaving(true) }} searchURL="/places/search/" />
                            </div>
                        </div>

                        <div className="row" hidden={expense.json?.type !== 'mileage'}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                Distance :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="distance" locked={saving} value={expense.json?.Distance ? expense.json?.Distance : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Distance: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" hidden={expense.json?.type !== 'mileage'}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("round_trip")} :
                            </div>
                            <Switch className='switch invoice-value ml-3'
                                checked={expense.json?.isRoundTrip ?? false}
                                width={70}
                                onColor="#F39800"
                                onHandleColor="#2693e6"
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                onChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, isRoundTrip: val } })); setSaving(true); }}
                            />
                        </div>

                        <div className="row" hidden={!['mileage', 'perdiem'].includes(expense.json?.type)}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {`${data.t.read("rate")} / `} {expense.json?.type === 'mileage' ? 'km' : data.t.read('day')} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.rate ?? null} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, rate: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.InvoiceDate) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("invoice.billing_date")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="date" locked={saving} value={expense.json?.InvoiceDate ? expense.json?.InvoiceDate : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, InvoiceDate: val } })); setSaving(true) }} />
                            </div>
                        </div>
                        <div className="row" style={{ display: showStatus(expense.json?.InvoiceNo) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("invoice.invoice_number")}:
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="data" locked={saving} value={expense.json?.InvoiceNo} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, InvoiceNo: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Currency) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("invoice.currency")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="data" locked={saving} value={expense.json?.Currency} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Currency: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row mb-1" style={{display: showStatus(expense.json?.Category)}}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("category")} :
                            </div>
                                <Select
                                    name="Category"
                                    value={{value: expense.json?.Category ?? data.t.read("n_a"), label: expense.json?.Category ?? data.t.read("n_a")}}
                                    options={[
                                        {value: data.t.read("n_a"), label: data.t.read("n_a")},
                                        ...imputations.map((imputation) => {
                                            return {value: imputation.category, label: imputation.category}
                                        })]
                                    }
                                    styles={styleSelect}
                                    className={data.isMobile ? "col-6 invoice-value pl-1" : "pl-1 col-3 invoice-value"}
                                    classNamePrefix="select"
                                    onChange={updateCategory}
                                    placeholder={'Category'}
                                />
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Total) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("invoice.total")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.Total ? expense.json?.Total : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Total: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Tax) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("invoice.tax")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={true} value={expense.json?.Tax ? expense.json?.Tax : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tax: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.TotalWithTax) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("invoice.total_with_tax")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={true} value={expense.json?.TotalWithTax ? expense.json?.TotalWithTax : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, TotalWithTax: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Tip) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                {data.t.read("tips")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.Tip ? expense.json?.Tip : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tip: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.BigTotalPayed) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                                Xpense {data.t.read("invoice.total")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={true} value={expense.json?.BigTotalPayed ? expense.json?.BigTotalPayed : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, BigTotalPayed: val } })); setSaving(true) }} />
                            </div>
                        </div>
                        <div className="mt-5" hidden={data.config?.frontend_invoice?.hide_cost_details_table}>
                            <h4 className="hidden-actions-container d-flex justify-content-between">
                                <span>
                                    {data.t.read("invoice.cost_details")}
                                    <span className="hidden-actions">
                                        <button disabled={saving} className="btn btn-sm btn-link" onClick={() => addItem(true)}>
                                            <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                        </button>
                                    </span>
                                </span>
                                <div className="text-right">
                                    <button className={"btn btn-secondary btn-sm btn-xs " + (billablesSelected.length > 0 ? "" : "faded")} onClick={() => confirmDeleteAction("billable")} >{data.t.read("delete")}</button>
                                </div>
                            </h4>
                            {hasBillableItems() &&
                                <table className={data.isMobile ? "table invoice-items-table mt-3 font-xsmall" : "table invoice-items-table mt-3 font-xsmall"}>
                                    <thead>
                                        <tr>
                                            <th style={{width: data.isMobile ? '20%' : '5%'}}>
                                                <Checkbox disabled={saving} isChecked={expense.json.ListItem.filter(item => isItemBillable(item)).length === billablesSelected.length && billablesSelected.length > 0} onChange={e => toggleSelectAll("billable")} />
                                            </th>
                                            <th className="col-3" >Description</th>
                                            <th className="col-3" hidden={data.isMobile}>{data.t.read("invoice.matter_id")}</th>
                                            <th className="col-2" hidden={data.isMobile} >{data.t.read("invoice.cost_type")}</th>
                                            <th className="col-2" hidden={data.isMobile} >{data.t.read("invoice.tax_code")}</th>
                                            <th className="col-2" >{data.t.read("invoice.amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            expense.json.ListItem.filter(item => isItemBillable(item)).map((item) => {
                                                return <tr key={"invoice-item-" + item._view_id}>
                                                    <td onClick={e => e.stopPropagation()} className="align-middle" >
                                                        <Checkbox isChecked={billablesSelected.findIndex(it => it._view_id === item._view_id) > -1} onChange={e => toggleItemSelected("billable", item)} />
                                                    </td>
                                                    <td className="align-middle">
                                                        <FormTextField format="data" locked={saving} value={item.Description} validateChange={val => updateItem({ ...item, Description: val })} />
                                                    </td>
                                                    <td className="align-middle" hidden={data.isMobile}>
                                                        <FormTextField format="data" locked={saving} value={item.MatterId} validateChange={val => updateItem({ ...item, MatterId: val })} />
                                                    </td>
                                                    <td className="align-middle" hidden={data.isMobile}>
                                                        <FormTextField format="data" locked={saving} value={item.CostType} validateChange={val => updateItem({ ...item, CostType: val })} />
                                                    </td>
                                                    <td className="align-middle" hidden={data.isMobile}>
                                                        <button onClick={() => updateItem({ ...item, TaxCode: getNextTaxCode(item.TaxCode ?? "N_IN" ) })}>{item.TaxCode ?? "N_IN"}</button>
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="position-relative">
                                                            <FormTextField format="money" locked={saving} value={item.Total} validateChange={val => updateItem({ ...item, Total: val })} />
                                                            <div className="item-actions">
                                                                <button className="btn btn-sm btn-link" onClick={() => setConfirmDeleteViewId(item._view_id)}>
                                                                    <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>}
                        </div>

                        <div className="mt-5">
                            <h4 className="hidden-actions-container d-flex justify-content-between">
                                <span>
                                    {data.t.read("invoice.gl_details")}
                                    <span className="hidden-actions">
                                        <button disabled={saving} className="btn btn-sm btn-link" onClick={() => addItem(false)}>
                                            <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                        </button>
                                    </span>
                                </span>
                                <div className="text-right">
                                    <button className={"btn btn-secondary btn-sm btn-xs " + (nonBillablesSelected.length > 0 ? "" : "faded")} onClick={() => confirmDeleteAction("non-billable")} >{data.t.read("delete")}</button>
                                </div>
                            </h4>
                            {hasUnBillableItems() &&
                                <table className={data.isMobile ? "table invoice-items-table mt-3 font-xsmall" : "table invoice-items-table mt-3 font-xsmall"}>
                                    <thead>
                                        <tr>
                                            <th style={{width: data.isMobile ? '20%' : '5%'}}>
                                                <Checkbox disabled={saving} isChecked={expense.json.ListItem.filter(item => !isItemBillable(item)).length === nonBillablesSelected.length && nonBillablesSelected.length > 0} onChange={e => toggleSelectAll("non-billable")} />
                                            </th>
                                            <th className="col-3" >Description</th>
                                            <th hidden={data.isMobile} className="col-3">{data.t.read("invoice.gl_nat")}</th>
                                            <th className="col-3" hidden={data.isMobile}>{data.t.read("invoice.tax_code")}</th>
                                            <th className="col-3">{data.t.read("invoice.amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            expense.json.ListItem.filter(item => !isItemBillable(item)).map((item) => {
                                                return <tr key={"invoice-item-" + item._view_id}>
                                                    <td onClick={e => e.stopPropagation()} className="align-middle">
                                                        <Checkbox isChecked={nonBillablesSelected.findIndex(it => it._view_id === item._view_id) > -1} onChange={e => toggleItemSelected("non-billable", item)} />
                                                    </td>
                                                    <td className="align-middle" >
                                                        <FormTextField format="data" locked={saving} value={item.Description} validateChange={val => updateItem({ ...item, Description: val })} />
                                                    </td>

                                                    <td className="align-middle" hidden={data.isMobile}>
                                                        <FormTextField format="data" locked={saving} value={item.GLNatural} validateChange={val => updateItem({ ...item, GLNatural: val })} />
                                                    </td>
                                                    <td className="align-middle" hidden={data.isMobile}>
                                                        <button onClick={() => updateItem({ ...item, TaxCode: getNextTaxCode(item.TaxCode ?? "N_IN" ) })}>{item.TaxCode ?? "N_IN"}</button>
                                                    </td>
                                                    <td>
                                                        <div className="position-relative">
                                                            <FormTextField format="money" locked={saving} value={item.Total} validateChange={val => updateItem({ ...item, Total: val })} />
                                                            <div className="item-actions">
                                                                <button className="btn btn-sm btn-link" onClick={() => setConfirmDeleteViewId(item._view_id)}>
                                                                    <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>}
                        </div>

                        <h4 className="mt-5" style={{ display: showStatus(expense.json?.Tax1) === "flex" || showStatus(expense.json?.Tax1) === "flex" ? "block" : "none" }}>{data.t.read("invoice.tax_details")}</h4>

                        <div className="row" style={{ display: showStatus(expense.json?.Tax1) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold" : "col-2 font-weight-bold"}>
                                {data.t.read("invoice.tax")} 1 :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.Tax1 ? expense.json.Tax1 : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tax1: val } })); setSaving(true) }} />
                            </div>
                        </div>
                        <div className="row" style={{ display: showStatus(expense.json?.Tax2) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold" : "col-2 font-weight-bold"}>
                                {data.t.read("invoice.tax")} 2 :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={validating | saving} value={expense.json?.Tax2 ? expense.json.Tax2 : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tax2: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <h4 className="mt-5">{data.t.read("comments")}</h4>
                        <div>
                            <FormTextAreaAutoSaved locked={validating || saving} value={expense.status_details} validateChange={val => { setExpense(prev => ({ ...prev, status_details: val })); setSaving(true) }} />
                        </div>

                        {
                            <div className="mt-5 d-flex justify-content-between">
                                <div>
                                    <button hidden={['mileage', 'perdiem'].includes(expense.json?.type)} className="btn btn-sm btn-secondary mr-2" onClick={() => setShowConfirmRestartProcess(true)}>
                                        {data.t.read("invoice.restart_processing")}
                                    </button>
                                </div>
                                {account_status === 'Submitted' &&
                                    <button className="btn btn-primary" disabled={validating | saving} onClick={() => validateExpense()}>
                                        {data.t.read("validate")}
                                        {validating && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                    </button>
                                }

                                {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) &&
                                    <div className="text-right">
                                        <button className={"btn ${data.isMobile ? 'col-8' : ''}` m-1  btn-primary"} hidden={expense.status !== "Need_Approval"}
                                            onClick={() => approveExpense()}>
                                            {data.t.read("approve")}
                                            {saving && action === 'approving' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                        </button>

                                        <button className="btn ${data.isMobile ? 'col-8' : ''}` m-1 btn-danger " hidden={expense.status !== "Need_Approval"} onClick={() => setShowDisapproval(true)}>
                                            {data.t.read("disapprove")}
                                        </button>
                                    </div>
                                }

                            </div>
                        }
                    </div>
                </div>
            }
            {
                showConfirmRestartProcess === true &&
                <Modal title={data.t.read("confirm")} cancelAction={() => setShowConfirmRestartProcess(false)} okAction={() => restartProcess()} okText={data.t.read("yes")} cancelText={data.t.read("cancel")}>
                    {data.t.read("invoice.restart_processing")} ?
                </Modal>

            }
            {
                confirmDeleteItems != null &&
                <Modal title={data.t.read("confirm")} cancelAction={() => setConfirmDeleteItems()} okAction={() => deleteItems()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                    {data.t.read("delete")} ?
                </Modal>

            }
            {
                confirmDeleteViewId != null &&
                <Modal title={data.t.read("confirm")} cancelAction={() => setConfirmDeleteViewId(null)} okAction={() => deleteItem(confirmDeleteViewId)} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                    {data.t.read("delete")} ?
                </Modal>

            }
            {
                savingError != null &&
                <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                    <div className="text-danger">
                        {savingError}
                    </div>
                </Modal>

            }
            {
                showDisapproval &&
                <Modal overflow="visible" title={data.t.read("denial_cause_placeholder")} cancelAction={() => setShowDisapproval(false)} okAction={() => disApproveExpense()} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                    <input className='form-control' type="text" onChange={e => setCauseDenial(e.target.value)} />
                </Modal>
            }
        </div>


}

export default Expense;